"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = require("styled-components");

var _oxygenConfig = require("@8x8/oxygen-config");

var _staticTooltip = _interopRequireWildcard(require("./static-tooltip.styled"));

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var StaticTooltip = function StaticTooltip(_ref) {
  var theme = _ref.theme,
      children = _ref.children,
      restProps = _objectWithoutProperties(_ref, ["theme", "children"]);

  return _react.default.createElement(_styledComponents.ThemeProvider, {
    theme: theme
  }, _react.default.createElement(_staticTooltip.default, restProps, _react.default.createElement(_staticTooltip.StaticTooltipOverflowContainer, null, children)));
};

StaticTooltip.propTypes = {
  theme: _propTypes.default.object.isRequired,
  children: _propTypes.default.node.isRequired,
  testId: _propTypes.default.string
};
StaticTooltip.defaultProps = {
  testId: 'STATIC_TOOLTIP'
};

var _default = (0, _oxygenConfig.withTheme)(StaticTooltip, 'staticTooltip');

exports.default = _default;