"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _getFont = _interopRequireDefault(require("./utils/getFont"));

var _getColor = _interopRequireDefault(require("./utils/getColor"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n  outline: none;\n  border: 0;\n  margin: 0;\n  padding: 0;\n  width: 100%;\n  background: transparent;\n\n  &::placeholder {\n    color: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var InputField = _styledComponents.default.input(_templateObject(), _getFont.default, _getColor.default, function (_ref) {
  var theme = _ref.theme;
  return theme.colorPlaceholder;
});

var _default = InputField;
exports.default = _default;