"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _getBorderColor = _interopRequireDefault(require("./utils/getBorderColor"));

var _getInputBorderRadius = _interopRequireDefault(require("./utils/getInputBorderRadius"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex: 1;\n  border-width: ", ";\n  border-style: ", ";\n  border-color: ", ";\n  border-left-width: ", ";\n  border-right-width: ", ";\n  border-radius: ", ";\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0 ", ";\n  transition: border-color ", "\n    ease-in-out;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var InputFieldWrapper = _styledComponents.default.div(_templateObject(), function (_ref) {
  var theme = _ref.theme;
  return theme.borderWidth;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.borderStyle;
}, _getBorderColor.default, function (_ref3) {
  var withPrefix = _ref3.withPrefix,
      theme = _ref3.theme;
  return withPrefix ? 0 : theme.borderWidth;
}, function (_ref4) {
  var withSuffix = _ref4.withSuffix,
      theme = _ref4.theme;
  return withSuffix ? 0 : theme.borderWidth;
}, function (_ref5) {
  var theme = _ref5.theme,
      withSuffix = _ref5.withSuffix,
      withPrefix = _ref5.withPrefix;
  return (0, _getInputBorderRadius.default)(theme, withSuffix, withPrefix);
}, function (_ref6) {
  var fixed = _ref6.fixed,
      theme = _ref6.theme;
  return fixed ? 0 : theme.horizontalSpacing;
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.borderTransitionTiming;
});

var _default = InputFieldWrapper;
exports.default = _default;