"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var getDecoratorBorderRadius = function getDecoratorBorderRadius(theme, isSuffix, isPrefix) {
  if (isSuffix) {
    return "0 ".concat(theme.borderRadius, " ").concat(theme.borderRadius, " 0");
  }

  if (isPrefix) {
    return "".concat(theme.borderRadius, " 0 0 ").concat(theme.borderRadius);
  }

  return theme.borderRadius;
};

var _default = getDecoratorBorderRadius;
exports.default = _default;