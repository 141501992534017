import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './mainFrame.module.scss';

export const MainFrame = ({
  children, className, style,
}) => {
  const classes = classnames(styles.card, className);
  return (
    <div className={styles.mainFrameWrapper}>
      <div className={classes} style={{ ...style }}>{children}</div>
    </div>
  );
};

MainFrame.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.node,
};
