"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _oxygenConstants = require("@8x8/oxygen-constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  text-align: ", ";\n\n  > *:not(:first-child) {\n    margin-left: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var ButtonGroupWrapper = _styledComponents.default.div(_templateObject(), function (_ref) {
  var align = _ref.align;
  return align;
}, function (props) {
  return props.spacing === _oxygenConstants.buttonSize.big ? _oxygenConstants.button.marginLeftLarge : _oxygenConstants.button.marginLeftSmall;
});

var _default = ButtonGroupWrapper;
exports.default = _default;