/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import HtmlParser from 'react-html-parser';
import Button from '@8x8/oxygen-button';

// custom style
import style from './thankyou.module.scss';

// components
import { Header } from '../../components/header';
import { MainFrame } from '../../components/mainFrame';
import { Loading } from '../loading';
import { Store } from '../../store';

export const Thankyou = () => {
  const { state: { thankyouPage }, dispatch } = useContext(Store);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchThankyouPage() {
      try {
        setLoading(true);
        const response = await axios.get('/api/thankyou');
        dispatch({
          type: 'THANKYOU_PAGE',
          payload: response.data,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    fetchThankyouPage();
  }, [dispatch]);

  return (
    loading ? <Loading /> : (
      <>
        <Header />
        <div className={style.thankyouWrapper}>
          <MainFrame>
            <div>
              <h5 className={style.heading}>{thankyouPage && thankyouPage.entries[0].reference[0].label_desktop_version}</h5>
              <div className={style.content}>{HtmlParser(thankyouPage && thankyouPage.entries[0].thank_you_description)}</div>
              <div className={style.buttonPosition}>
                {
                  thankyouPage
                  && <Button onClick={() => { window.location.href = 'https://www.8x8.com/'; }}>{thankyouPage.entries[0].cta_reference[0].cta_label}</Button>
                }
              </div>
            </div>
          </MainFrame>
        </div>
      </>
    )
  );
};
