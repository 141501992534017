"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.marginsIndexMap = exports.directionsIndexMap = exports.spacesIndexMap = exports.directionsType = exports.directions = void 0;

var _invert = _interopRequireDefault(require("lodash/invert"));

var _marginsIndexMap;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var directions = {
  top: 'top',
  right: 'right',
  bottom: 'bottom',
  left: 'left',
  topRight: 'topRight',
  topLeft: 'topLeft',
  bottomRight: 'bottomRight',
  bottomLeft: 'bottomLeft'
};
exports.directions = directions;
var directionsType = Object.values(directions);
exports.directionsType = directionsType;
var spacesIndexMap = {
  0: directions.top,
  1: directions.right,
  2: directions.bottom,
  3: directions.left,
  4: directions.topRight,
  5: directions.topLeft,
  6: directions.bottomRight,
  7: directions.bottomLeft
};
exports.spacesIndexMap = spacesIndexMap;
var directionsIndexMap = (0, _invert.default)(spacesIndexMap);
exports.directionsIndexMap = directionsIndexMap;
var marginsIndexMap = (_marginsIndexMap = {}, _defineProperty(_marginsIndexMap, directions.top, 'bottom'), _defineProperty(_marginsIndexMap, directions.right, 'left'), _defineProperty(_marginsIndexMap, directions.bottom, 'top'), _defineProperty(_marginsIndexMap, directions.left, 'right'), _defineProperty(_marginsIndexMap, directions.topRight, 'bottomRight'), _defineProperty(_marginsIndexMap, directions.topLeft, 'bottomLeft'), _defineProperty(_marginsIndexMap, directions.bottomRight, 'topRight'), _defineProperty(_marginsIndexMap, directions.bottomLeft, 'topLeft'), _marginsIndexMap);
exports.marginsIndexMap = marginsIndexMap;