"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _size = _interopRequireDefault(require("../choices/size.const"));

var _color = _interopRequireDefault(require("../choices/color.const"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var modalFooter = {
  buttonSpacing: _size.default.default2x,
  paddingVertical: _size.default.default2x,
  paddingHorizontal: _size.default.medium,
  paddingSmallHorizontal: _size.default.default2x,
  shadowSize: _size.default.shadowSmall,
  shadowColor: _color.default.cloudyBlue300
};
var _default = modalFooter;
exports.default = _default;