"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _oxygenConfig = require("@8x8/oxygen-config");

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  height: ", ";\n  width: ", ";\n  cursor: ", ";\n  ", ";\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function getIconColor(_ref) {
  var active = _ref.active,
      color = _ref.color,
      disabled = _ref.disabled,
      invertColors = _ref.invertColors,
      link = _ref.link,
      selected = _ref.selected,
      theme = _ref.theme;

  if (color) {
    return "fill: ".concat(color);
  }

  if (disabled) {
    return "fill: ".concat(invertColors ? theme.disabledInvertColor : theme.disabledColor);
  }

  if (active) {
    return "fill: ".concat(invertColors ? theme.hoverInvertColor : theme.hoverColor);
  }

  if (selected) {
    return "fill: ".concat(invertColors ? theme.selectedInvertColor : theme.selectedColor);
  }

  if (link) {
    return "fill: ".concat(invertColors ? theme.linkInvertColor : theme.linkColor);
  }

  return "fill: ".concat(invertColors ? theme.noLinkInvertColor : theme.color);
}

var StyledComponent = _styledComponents.default.svg.attrs(function (_ref2) {
  var theme = _ref2.theme;
  return {
    viewBox: theme.viewBox
  };
})(_templateObject(), function (_ref3) {
  var size = _ref3.size,
      theme = _ref3.theme;
  return size ? "".concat(size, "px") : theme.size;
}, function (_ref4) {
  var size = _ref4.size,
      theme = _ref4.theme;
  return size ? "".concat(size, "px") : theme.size;
}, function (_ref5) {
  var link = _ref5.link,
      disabled = _ref5.disabled;
  return link && !disabled ? 'pointer' : 'default';
}, getIconColor, function (_ref6) {
  var theme = _ref6.theme,
      invertColors = _ref6.invertColors,
      link = _ref6.link;
  return link ? "\n    &:hover {\n    ".concat(getIconColor({
    theme: theme,
    invertColors: invertColors,
    link: link,
    active: true
  }), ";\n    }\n\n    &:focus {\n    ").concat(getIconColor({
    theme: theme,
    invertColors: invertColors,
    link: link,
    active: true
  }), ";\n    }\n\n    &[disabled] {\n    ").concat(getIconColor({
    theme: theme,
    invertColors: invertColors,
    link: link,
    disabled: true
  }), ";\n    }\n  ") : '';
});

var Icon = function Icon(props) {
  var children = props.children,
      rest = _objectWithoutProperties(props, ["children"]);

  return _react.default.createElement(StyledComponent, rest, children);
};

Icon.propTypes = {
  active: _propTypes.default.bool,
  children: _propTypes.default.node.isRequired,
  color: _propTypes.default.string,
  invertColors: _propTypes.default.bool,
  disabled: _propTypes.default.bool,
  link: _propTypes.default.bool,
  selected: _propTypes.default.bool,
  size: _propTypes.default.number,
  theme: _propTypes.default.object.isRequired
};
Icon.defaultProps = {
  active: false,
  disabled: false,
  color: null,
  invertColors: false,
  link: false,
  selected: false,
  size: null
};

var _default = (0, _oxygenConfig.withTheme)(Icon, 'icon');

exports.default = _default;