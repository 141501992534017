"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var getBackgroundColor = function getBackgroundColor(_ref) {
  var theme = _ref.theme,
      fixed = _ref.fixed,
      isDisabled = _ref.isDisabled;

  if (fixed) {
    return theme.backgroundColorFixed;
  }

  if (isDisabled) {
    return theme.backgroundColorDisabled;
  }

  return theme.backgroundColor;
};

var _default = getBackgroundColor;
exports.default = _default;