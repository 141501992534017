"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _polished = require("polished");

var _getFont = _interopRequireDefault(require("./utils/getFont"));

var _getBorderColor = _interopRequireDefault(require("./utils/getBorderColor"));

var _getDecoratorBorderRadius = _interopRequireDefault(require("./utils/getDecoratorBorderRadius"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  align-items: center;\n  background-color: ", ";\n  border: ", ";\n  border-right-color: ", ";\n  border-left-color: ", ";\n  box-sizing: border-box;\n  color: ", ";\n  display: flex;\n  padding: 0 ", ";\n  text-align: left;\n  transition: border-color ", ";\n  transition-timing-function: ease-in-out;\n  width: ", ";\n  border-radius: ", ";\n\n  & > span {\n    ", "\n    ", "\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var getDecoratorBorderColor = function getDecoratorBorderColor(predicate) {
  return function (_ref) {
    var theme = _ref.theme,
        focused = _ref.focused,
        props = _objectWithoutProperties(_ref, ["theme", "focused"]);

    if (predicate) {
      return (0, _getBorderColor.default)(_objectSpread({
        theme: theme,
        focused: focused,
        defaultColor: theme.borderColorSemanticBlock
      }, props));
    }

    return theme.borderColorSemanticBlock;
  };
};

var InputDecorator = _styledComponents.default.div(_templateObject(), function (_ref2) {
  var theme = _ref2.theme;
  return theme.backgroundColorSemanticBlock;
}, function (_ref3) {
  var theme = _ref3.theme;
  return "".concat(theme.borderWidthSemanticBlock, " ").concat(theme.borderStyleDefaultSemanticBlock, " ").concat(theme.borderColorSemanticBlock);
}, function (_ref4) {
  var isPrefix = _ref4.isPrefix;
  return getDecoratorBorderColor(isPrefix);
}, function (_ref5) {
  var isPrefix = _ref5.isPrefix;
  return getDecoratorBorderColor(!isPrefix);
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.colorSemanticBlock;
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.horizontalSpacing;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.borderTransitionTiming;
}, function (_ref9) {
  var theme = _ref9.theme;
  return theme.widthSemanticBlock;
}, function (_ref10) {
  var theme = _ref10.theme,
      isSuffix = _ref10.isSuffix,
      isPrefix = _ref10.isPrefix;
  return (0, _getDecoratorBorderRadius.default)(theme, isSuffix, isPrefix);
}, _getFont.default, (0, _polished.ellipsis)());

var _default = InputDecorator;
exports.default = _default;