"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Icon;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _oxygenTooltip = _interopRequireWildcard(require("@8x8/oxygen-tooltip"));

var _InfoIcon = _interopRequireDefault(require("@8x8/oxygen-icon/icons/InfoIcon"));

var _IconWrapper = _interopRequireDefault(require("../styled/IconWrapper"));

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Icon(_ref) {
  var theme = _ref.theme,
      iconText = _ref.iconText;
  return _react.default.createElement(_IconWrapper.default, null, _react.default.createElement(_oxygenTooltip.default, {
    title: iconText,
    orientation: _oxygenTooltip.directions.top,
    showOn: _oxygenTooltip.showOnTypes.hover
  }, _react.default.createElement(_InfoIcon.default, {
    color: theme.iconColor,
    size: theme.iconSize
  })));
}

Icon.propTypes = {
  theme: _propTypes.default.object.isRequired,
  iconText: _propTypes.default.string.isRequired
};