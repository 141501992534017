"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.sizes = exports.mediaFn = exports.mediaMin = exports.getMediaVal = exports.calcMediaVal = exports.mediaMaxC = exports.mediaMinC = exports.getCssMedia = void 0;

var _styledComponents = require("styled-components");

var _oxygenConstants = require("@8x8/oxygen-constants");

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["@media (", "-width: ", ") {\n     ", "\n  }"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var sizes = {
  desktop: _oxygenConstants.size.windowWidthDesktop,
  tablet: _oxygenConstants.size.windowWidthTablet,
  phone: _oxygenConstants.size.windowWidthPhone
};
/**
 * @description Convert pixels to em units
 * @param {(number|string)} value Breakpoint value
 * @returns {number} A number which was converted to em units
 */

exports.sizes = sizes;

var calcMediaVal = function calcMediaVal(value) {
  return parseFloat(value, 10) / 16;
};
/**
 * @description Append `em` to a number
 * @param {number} value Breakpoint value
 * @returns {string} A number which was converted to em units with `em` appended
 */


exports.calcMediaVal = calcMediaVal;

var getMediaVal = function getMediaVal(value) {
  return "".concat(calcMediaVal(value), "em");
};
/**
 * @param {string} [mediaRule=max] Prefix for the media rule: [min|max]
 * @returns {(value: (number|string)) => (...args: string) => string}
 */


exports.getMediaVal = getMediaVal;

var getCssMedia = function getCssMedia() {
  var mediaRule = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'max';
  return function (value) {
    return function () {
      return (0, _styledComponents.css)(_templateObject(), mediaRule, getMediaVal(value), _styledComponents.css.apply(void 0, arguments));
    };
  };
};
/**
 * @description Function to create an object containing media query functions for `styled-components`
 * @param {Array<[string, string | number]>} [sizeEntries=max] Media query [max|min] width rule
 * @param {string}                           [mediaRule=max] Media query [max|min] width rule
 * @returns {{ desktop: Function, tablet: Function, phone: Function }} - Utility media-query functions
 * for `styled-components`
 */


exports.getCssMedia = getCssMedia;

var mediaFn = function mediaFn() {
  var sizeEntries = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : sizes;
  return function () {
    var mediaRule = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'max';
    return Object.entries(sizeEntries).reduce(function (acc, _ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          val = _ref2[1];

      return _objectSpread({}, acc, _defineProperty({}, key, getCssMedia(mediaRule)(val)));
    }, {});
  };
};
/**
 * @description Creates a min-width rule with custom value
 * @param {(number|string)} value Breakpoint value
 * @returns {(css: string) => string}
 */


exports.mediaFn = mediaFn;
var mediaMinC = getCssMedia('min');
/**
 * @description Creates a max-width rule with custom value
 * @param {(number|string)} value Breakpoint value
 * @returns {(css: string) => string}
 */

exports.mediaMinC = mediaMinC;
var mediaMaxC = getCssMedia();
/**
 * @description Object containing `min-width` breakpoint functions for `styled-components`
 */

exports.mediaMaxC = mediaMaxC;
var mediaMin = mediaFn()('min');
/**
 * @description Object containing `max-width` breakpoint functions for `styled-components`
 */

exports.mediaMin = mediaMin;
var mediaMax = mediaFn()();
var _default = mediaMax;
exports.default = _default;