"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOP = exports.SELF = exports.PARENT = exports.BLANK = void 0;
var BLANK = '_blank';
exports.BLANK = BLANK;
var PARENT = '_parent';
exports.PARENT = PARENT;
var SELF = '_self';
exports.SELF = SELF;
var TOP = '_top';
exports.TOP = TOP;