import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Helmet } from 'react-helmet';
import { Switch, Route } from 'react-router';
import { Payment } from './containers/payment';
import { Thankyou } from './containers/thankyou';
import { ErrorPage } from './containers/errorPage';
import { PayNow } from './containers/payNow';
import { Success } from './containers/successPage';
import { Failure } from './containers/failurePage';

function App() {
  const [helmet, setHelmet] = useState(null);

  useEffect(() => {
    (async () => {
      const { data: { entries } } = await Axios.get('/api/helmat');
      setHelmet(entries);
    })();
  }, []);

  return (
      <>
        <Helmet>
          <meta charset="utf-8" />
          <link rel="shortcut icon" href={helmet && helmet[0]?.logo?.url} />
          <title>{helmet ? helmet[0]?.title_name : '...'}</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <Switch>
          <Route exact path="/" component={Thankyou} />
          <Route exact path="/paynow" component={PayNow} />
          <Route exact path="/payment" component={Payment} />
          <Route exact path="/success" component={Success} />
          <Route exact path="/failure" component={Failure} />
          <Route exact path="/thankyou" component={Thankyou} />
          <Route exact path="/problem" component={ErrorPage} />
          <Route exact path="/error" component={ErrorPage} />
          <Route any component={Thankyou} />
        </Switch>
      </>
  );
}

export default App;
