"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Radio = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = require("styled-components");

var _oxygenConfig = require("@8x8/oxygen-config");

var _oxygenLabel = _interopRequireDefault(require("@8x8/oxygen-label"));

var _StyledRadio = require("../styled/StyledRadio");

var _RadioGroup = require("./RadioGroup");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var handleOnChange = function handleOnChange(onChange, value) {
  return function () {
    onChange(value);
  };
};

var Radio = function Radio(props) {
  return _react.default.createElement(_RadioGroup.RadioGroupContext.Consumer, null, function (radioGroup) {
    var children = props.children,
        isDisabled = props.isDisabled,
        label = props.label,
        theme = props.theme,
        value = props.value,
        isChecked = props.isChecked,
        name = props.name,
        onChange = props.onChange,
        rest = _objectWithoutProperties(props, ["children", "isDisabled", "label", "theme", "value", "isChecked", "name", "onChange"]);

    var radioChecked = isChecked;
    var radioName = name;
    var radioOnChange = onChange;

    if (radioGroup) {
      // if the radio is not used as standalone component and it's controlled through the group
      // we must use the props from group context
      radioChecked = _typeof(radioGroup.selectedValue) === 'object' && _typeof(value) === 'object' ? radioGroup.selectedValue.id === value.id : radioGroup.selectedValue === value;
      radioName = radioGroup.name;
      radioOnChange = radioGroup.onChange;
    }

    return _react.default.createElement(_styledComponents.ThemeProvider, {
      theme: theme
    }, _react.default.createElement(_react.Fragment, null, _react.default.createElement(_StyledRadio.RadioContainer, _extends({
      isDisabled: isDisabled
    }, rest), _react.default.createElement(_oxygenLabel.default, {
      disabled: isDisabled,
      value: _react.default.createElement(_styledComponents.ThemeProvider, {
        theme: theme
      }, _react.default.createElement(_react.Fragment, null, _react.default.createElement(_StyledRadio.RadioIcon, {
        isChecked: radioChecked,
        isDisabled: isDisabled,
        label: label
      }), _react.default.createElement(_StyledRadio.RadioInput, {
        checked: radioChecked,
        disabled: isDisabled,
        name: radioName,
        onChange: handleOnChange(radioOnChange, value),
        type: "radio"
      }), label))
    })), children));
  });
};

exports.Radio = Radio;
Radio.propTypes = {
  /**
   * Children to be rendered after the input and label
   */
  children: _propTypes.default.oneOfType([_propTypes.default.array, _propTypes.default.node]),

  /**
   * Specifies if the input is checked
   */
  isChecked: _propTypes.default.bool,

  /**
   * Radio inputs to be rendered
   */
  isDisabled: _propTypes.default.bool,

  /**
   * Specifies the label for the radio input
   */
  label: _propTypes.default.string,

  /**
   * Specifies the name prop for the radio input
   */
  name: _propTypes.default.string,

  /**
   * Callback for the radio input
   */
  onChange: _propTypes.default.func,

  /**
   * Radio theme
   */
  theme: _propTypes.default.object,

  /**
   * Radio input value (used to match with the selected value of the group)
   */
  value: _propTypes.default.oneOfType([_propTypes.default.number, _propTypes.default.string, _propTypes.default.bool, _propTypes.default.shape({
    id: _propTypes.default.string.isRequired
  })])
};
Radio.defaultProps = {
  children: null,
  isChecked: false,
  isDisabled: false,
  label: '',
  name: '',
  onChange: function onChange() {
    return false;
  },
  theme: {},
  value: ''
}; // for testing only

var _default = (0, _oxygenConfig.withTheme)(Radio, 'radio');

exports.default = _default;