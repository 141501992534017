"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _StyledRadio = require("./StyledRadio");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: ", ";\n  ", " ~ ", " {\n    ", "\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var getFlexDirection = function getFlexDirection(isHorizontal) {
  return isHorizontal ? 'row' : 'column';
};

var getMargin = function getMargin(isHorizontal, theme) {
  return "".concat(isHorizontal ? 'margin-left' : 'margin-top', " ").concat(theme.margin);
};

var StyledRadioGroup = _styledComponents.default.div(_templateObject(), function (_ref) {
  var isHorizontal = _ref.isHorizontal;
  return getFlexDirection(isHorizontal);
}, _StyledRadio.RadioContainer, _StyledRadio.RadioContainer, function (props) {
  return getMargin(props.isHorizontal, props.theme);
});

var _default = StyledRadioGroup;
exports.default = _default;