"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.textLink = void 0;

var _color = _interopRequireDefault(require("../choices/color.const"));

var _font = _interopRequireDefault(require("../choices/font.const"));

var _timing = _interopRequireDefault(require("../choices/timing.const"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var textLink = {
  textLinkFontFamily: _font.default.family,
  textLinkFontSize: _font.default.sizeDefault,
  textLinkFontWeight: _font.default.weightDefault,
  textLinkLineHeight: _font.default.lineHeightMedium,
  textLinkColorPrimary: _color.default.dodgerBlue,
  textLinkColorPrimaryHover: _color.default.dodgerBlue300,
  textLinkColorPrimaryActive: _color.default.dodgerBlue,
  textLinkColorPrimaryDisabled: _color.default.dodgerBlue300,
  textLinkColorPrimarySelected: 'inherit',
  textLinkBackgroundColorPrimarySelected: 'inherit',
  textLinkMargin: '0',
  textLinkPadding: '0',
  textLinkTransitionTime: _timing.default.fast
};
exports.textLink = textLink;
var _default = textLink;
exports.default = _default;