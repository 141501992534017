"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.inputSize = exports.input = void 0;

var _color = _interopRequireDefault(require("../choices/color.const"));

var _font = _interopRequireDefault(require("../choices/font.const"));

var _size = _interopRequireDefault(require("../choices/size.const"));

var _timing = _interopRequireDefault(require("../choices/timing.const"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var input = {
  height: _size.default.formItemSizeLarge,
  heightSmall: _size.default.formItemSizeSmall,
  backgroundColor: _color.default.white,
  backgroundColorFixed: 'transparent',
  backgroundColorDisabled: _color.default.cloudyBlue100,
  backgroundColorSemanticBlock: _color.default.cloudyBlue100,
  borderWidth: _size.default.borderSizeDefault,
  borderWidthSemanticBlock: _size.default.borderSizeDefault,
  borderStyle: 'solid',
  borderStyleDefaultSemanticBlock: 'solid',
  borderColor: _color.default.cloudyBlue300,
  borderColorFocused: _color.default.dodgerBlue,
  borderColorError: _color.default.coral,
  borderColorFixed: 'transparent',
  borderColorSemanticBlock: _color.default.cloudyBlue,
  borderTransitionTiming: _timing.default.fast,
  borderRadius: 0,
  color: _color.default.pickledBluewood800,
  colorIcon: _color.default.steel700,
  colorFixed: _color.default.steel700,
  colorDisabled: _color.default.cloudyBlue,
  colorPlaceholder: _color.default.cloudyBlue,
  colorSemanticBlock: _color.default.steel700,
  fontFamily: _font.default.family,
  fontSize: _font.default.sizeDefault,
  descriptionFontSize: _font.default.sizeSmall,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: _font.default.weightDefault,
  letterSpacing: 'normal',
  lineHeight: _font.default.lineHeightMedium,
  horizontalSpacing: _size.default.default,
  verticalSpacing: _size.default.small,
  width: '296px',
  widthSemanticBlock: '60px',
  minWidth: '40px'
};
exports.input = input;
var inputSize = {
  small: 'small',
  default: 'default'
};
exports.inputSize = inputSize;