"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _oxygenConfig = require("@8x8/oxygen-config");

var _oxygenConstants = require("@8x8/oxygen-constants");

var _themeName = _interopRequireDefault(require("../constants/themeName"));

var _ButtonGroupWrapper = _interopRequireDefault(require("../styled/ButtonGroupWrapper"));

var _groupAlign = require("../constants/groupAlign");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ButtonGroup = function ButtonGroup(_ref) {
  var children = _ref.children,
      spacing = _ref.spacing,
      align = _ref.align;
  return _react.default.createElement(_ButtonGroupWrapper.default, {
    spacing: spacing,
    align: align
  }, children);
};

ButtonGroup.propTypes = {
  children: _propTypes.default.oneOfType([_propTypes.default.array, _propTypes.default.node]).isRequired,
  spacing: _propTypes.default.oneOf([_oxygenConstants.buttonSize.small, _oxygenConstants.buttonSize.big]),
  align: _propTypes.default.oneOf([_groupAlign.LEFT, _groupAlign.CENTER, _groupAlign.RIGHT])
};
ButtonGroup.defaultProps = {
  spacing: _oxygenConstants.buttonSize.big,
  align: _groupAlign.CENTER
};

var _default = (0, _oxygenConfig.withTheme)(ButtonGroup, _themeName.default);

exports.default = _default;