"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Action;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _isFunction = _interopRequireDefault(require("lodash/isFunction"));

var _ActionText = _interopRequireDefault(require("../styled/ActionText"));

var _targets = require("../constants/targets");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function Action(_ref) {
  var children = _ref.children,
      action = _ref.action,
      actionTarget = _ref.actionTarget;
  return _react.default.createElement(_ActionText.default, _extends({
    target: "".concat(actionTarget)
  }, _defineProperty({}, (0, _isFunction.default)(action) ? 'onClick' : 'href', action)), children);
}

Action.propTypes = {
  children: _propTypes.default.oneOfType([_propTypes.default.string, _propTypes.default.number]),
  action: _propTypes.default.oneOfType([_propTypes.default.func, _propTypes.default.string]),
  actionTarget: _propTypes.default.oneOf([_targets.BLANK, _targets.SELF, _targets.PARENT, _targets.TOP])
};
Action.defaultProps = {
  children: undefined,
  action: undefined,
  actionTarget: _targets.SELF
};