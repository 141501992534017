import React from 'react';
import PropTypes from 'prop-types';

export const Store = React.createContext();

const initialState = {};

function reducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case 'PAYMENT_PAGE':
      return { ...state, paymentPage: payload };
    case 'THANKYOU_PAGE':
      return { ...state, thankyouPage: payload };
    case 'PAYMENT_GATEWAY_URL':
      return { ...state, payGatewayUrl: payload };
    case 'FETCH_DATA':
      return { ...state, users: payload };
    case 'GET_QUERY_PARAM':
      return { ...state, queryParam: payload };
    case 'ERROR_PAGE':
      return { ...state, errorPage: payload };
    case 'SUCCESS_PAGE':
      return { ...state, successPage: payload };
    case 'FAILURE_PAGE':
      return { ...state, failurePage: payload };
    case 'PAYNOW_PAGE':
      return { ...state, paynowPage: payload };
    case 'PAY_REQUEST':
      return { ...state, reqPayment: payload };
    default:
      return state;
  }
}

export function StoreProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <Store.Provider value={value}>{ children }</Store.Provider>;
}

StoreProvider.propTypes = {
  children: PropTypes.object.isRequired,
};
