import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import HtmlParser from 'react-html-parser';

// components
import { Header } from '../../components/header';
import { MainFrame } from '../../components/mainFrame';
import { Loading } from '../loading';
import { Store } from '../../store';

// custom style
import style from './failurePage.module.scss';

export const Failure = () => {
  const { state: { failurePage }, dispatch } = useContext(Store);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchThankyouPage() {
      try {
        setLoading(true);
        const response = await axios.get('/api/failure');
        dispatch({
          type: 'FAILURE_PAGE',
          payload: response.data,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    fetchThankyouPage();
  }, [dispatch]);

  return (
    loading ? <Loading /> : (
      <>
        <Header />
        <MainFrame>
          <div className={style.failurePageWrapper}>
            {
              failurePage && failurePage.entries[0].failure_message
              && (
              <div
                className={style.content}
              >
                {HtmlParser(failurePage && failurePage.entries[0].failure_message)}
              </div>
              )
            }
          </div>
        </MainFrame>
      </>
    )
  );
};
