"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.modalSize = exports.modal = void 0;

var _polished = require("polished");

var _styledComponents = require("styled-components");

var _color = _interopRequireDefault(require("../choices/color.const"));

var _size = _interopRequireDefault(require("../choices/size.const"));

var _timing = _interopRequireDefault(require("../choices/timing.const"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  animation: ", " ", " ease-in;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  0% {\n    opacity: 0;\n    transform: translateY(-10px);\n  }\n  70% {\n    opacity: 0;\n    transform: translateY(-10px);\n  }\n  100% {\n    opacity: 1;\n    transform: translateY(0);\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var animation = (0, _styledComponents.keyframes)(_templateObject());
var fadeInAnimation = (0, _styledComponents.css)(_templateObject2(), animation, _timing.default.medium);
var modal = {
  widthSmall: '620px',
  widthLarge: '960px',
  minHeight: '360px',
  maxHeight: '600px',
  backgroundColor: _color.default.white,
  zIndex: 100000,
  zIndexContainer: 99999,
  shadowSize: _size.default.shadowMedium,
  shadowColor: (0, _polished.rgba)(_color.default.pickledBluewood800, 0.1),
  hasShadow: true,
  borderRadius: _size.default.borderRadiusMedium,
  animation: fadeInAnimation
};
exports.modal = modal;
var modalSize = {
  small: 'small',
  big: 'big'
};
exports.modalSize = modalSize;