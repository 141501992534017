"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.StaticTooltipOverflowContainer = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var config = _interopRequireWildcard(require("@8x8/oxygen-config"));

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  border: ", ";\n  border-radius: ", ";\n  box-sizing: border-box;\n  box-shadow: ", ";\n  color: ", ";\n  font-family: ", ";\n  font-size: ", ";\n  font-weight: normal;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: ", ";\n  letter-spacing: normal;\n  margin: 0;\n  max-height: ", ";\n  max-width: ", ";\n  padding: ", ";\n  position: relative;\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  box-sizing: border-box;\n  max-height: ", ";\n  overflow-x: auto;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var getTooltipOverflowContainerMaxHeight = function getTooltipOverflowContainerMaxHeight(_ref) {
  var theme = _ref.theme;
  return "".concat(parseInt(theme.maxHeight, 10) - parseInt(theme.padding, 10) * 2, "px");
};

var StaticTooltipOverflowContainer = _styledComponents.default.div(_templateObject(), getTooltipOverflowContainerMaxHeight);

exports.StaticTooltipOverflowContainer = StaticTooltipOverflowContainer;

var TooltipContainer = _styledComponents.default.div.attrs(function (_ref2) {
  var testId = _ref2.testId;
  return _objectSpread({}, config.getTestAttributes(testId));
})(_templateObject2(), function (_ref3) {
  var theme = _ref3.theme;
  return theme.backgroundColor;
}, function (_ref4) {
  var theme = _ref4.theme;
  return "".concat(theme.borderWidth, " ").concat(theme.borderStyle, " ").concat(theme.borderColor);
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.borderRadius;
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.boxShadow;
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.color;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.fontFamily;
}, function (_ref9) {
  var theme = _ref9.theme;
  return theme.fontSize;
}, function (_ref10) {
  var theme = _ref10.theme;
  return theme.lineHeight;
}, function (_ref11) {
  var theme = _ref11.theme;
  return theme.maxHeight;
}, function (_ref12) {
  var theme = _ref12.theme;
  return theme.maxWidth;
}, function (_ref13) {
  var theme = _ref13.theme;
  return theme.padding;
}, function (_ref14) {
  var theme = _ref14.theme;
  return theme.animation;
});

var _default = TooltipContainer;
exports.default = _default;