"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _ellipsis = _interopRequireDefault(require("polished/lib/mixins/ellipsis"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-style: normal;\n  font-stretch: ", ";\n  font-weight: ", ";\n  letter-spacing: ", ";\n  line-height: ", ";\n  color: ", ";\n  cursor: ", ";\n  display: block;\n\n  ", "\n\n  &::before {\n    content: '* ';\n    color: ", ";\n    font-size: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var ValueText = _styledComponents.default.label(_templateObject(), function (_ref) {
  var theme = _ref.theme;
  return theme.fontStretch;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.fontWeight;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.letterSpacing;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.lineHeight;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.fontColor;
}, function (_ref6) {
  var disabled = _ref6.disabled;
  return disabled ? 'default' : 'pointer';
}, (0, _ellipsis.default)(), function (_ref7) {
  var theme = _ref7.theme;
  return theme.fontColorStarRequired;
}, function (_ref8) {
  var theme = _ref8.theme,
      required = _ref8.required;
  return required ? theme.fontSize : 0;
});

var _default = ValueText;
exports.default = _default;