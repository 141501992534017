"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.URL = exports.TEXT = exports.PASSWORD = exports.NUMBER = exports.EMAIL = exports.DATE = void 0;
var DATE = 'date';
exports.DATE = DATE;
var EMAIL = 'email';
exports.EMAIL = EMAIL;
var NUMBER = 'number';
exports.NUMBER = NUMBER;
var PASSWORD = 'password';
exports.PASSWORD = PASSWORD;
var TEXT = 'text';
exports.TEXT = TEXT;
var URL = 'url';
exports.URL = URL;