"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _styledTools = require("styled-tools");

var _oxygenConstants = require("@8x8/oxygen-constants");

var _getBackgroundColor = _interopRequireDefault(require("./utils/getBackgroundColor"));

var _getDecoratorBorderRadius = _interopRequireDefault(require("./utils/getDecoratorBorderRadius"));

var _switchProp;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background: ", ";\n  border-radius: ", ";\n  height: ", ";\n  display: flex;\n  margin: ", " 0;\n  min-width: ", ";\n  width: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var InputWrapper = _styledComponents.default.div(_templateObject(), _getBackgroundColor.default, function (_ref) {
  var theme = _ref.theme,
      isSuffix = _ref.isSuffix,
      isPrefix = _ref.isPrefix;
  return (0, _getDecoratorBorderRadius.default)(theme, isSuffix, isPrefix);
}, (0, _styledTools.switchProp)((0, _styledTools.prop)('size', [_oxygenConstants.inputSize.default]), (_switchProp = {}, _defineProperty(_switchProp, _oxygenConstants.inputSize.small, function (_ref2) {
  var theme = _ref2.theme;
  return theme.heightSmall;
}), _defineProperty(_switchProp, _oxygenConstants.inputSize.default, function (_ref3) {
  var theme = _ref3.theme;
  return theme.height;
}), _switchProp)), function (_ref4) {
  var theme = _ref4.theme;
  return theme.verticalSpacing;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.minWidth;
}, function (_ref6) {
  var fullWidth = _ref6.fullWidth,
      theme = _ref6.theme;
  return fullWidth ? '100%' : theme.width;
});

var _default = InputWrapper;
exports.default = _default;