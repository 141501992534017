"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  margin-left: ", ";\n  cursor: pointer;\n\n  &,\n  &:hover,\n  &:active,\n  &:focus {\n    text-decoration: none;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var ActionText = _styledComponents.default.a(_templateObject(), function (_ref) {
  var theme = _ref.theme;
  return theme.fontColorAction;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.height;
});

var _default = ActionText;
exports.default = _default;