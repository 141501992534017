"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _isNaN = _interopRequireDefault(require("lodash/isNaN"));

var _oxygenConfig = require("@8x8/oxygen-config");

var _oxygenConstants = require("@8x8/oxygen-constants");

var _themeName = _interopRequireDefault(require("../constants/themeName"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var safeNum = 0.1;
var sizeToBorderRatio = 24;

var getSize = function getSize(_ref) {
  var theme = _ref.theme,
      size = _ref.size;

  if (!(0, _isNaN.default)(parseInt(size, 10))) {
    return size;
  }

  if (size === _oxygenConstants.spinnerSize.small) {
    return theme.widthSpinnerSmall;
  }

  if (size === _oxygenConstants.spinnerSize.large2x) {
    return theme.widthSpinnerExtraLarge;
  }

  return theme.widthSpinnerDefault;
};

var Spinner = function Spinner(_ref2) {
  var theme = _ref2.theme,
      size = _ref2.size,
      strokeWidth = _ref2.strokeWidth,
      hasAnimation = _ref2.hasAnimation;
  var computedSize = getSize({
    theme: theme,
    size: size
  });
  var strokeWidth$ = strokeWidth || computedSize / sizeToBorderRatio;
  var computedSizeHalf = Math.max(safeNum, Math.floor(computedSize / 2));
  var computedSizeQuarter = Math.max(safeNum, Math.floor(computedSizeHalf / 2));
  var computedRadius = Math.max(safeNum, computedSizeQuarter - strokeWidth$);
  return _react.default.createElement("svg", {
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    preserveAspectRatio: "xMidYMid",
    viewBox: "0 0 ".concat(computedSizeHalf, " ").concat(computedSizeHalf),
    height: "".concat(computedSize, "px"),
    width: "".concat(computedSize, "px")
  }, _react.default.createElement("circle", {
    r: computedRadius,
    cx: computedSizeQuarter,
    cy: computedSizeQuarter,
    fill: "none",
    stroke: theme.backgroundContainer,
    strokeWidth: strokeWidth$
  }), _react.default.createElement("circle", {
    r: computedRadius,
    cx: computedSizeQuarter,
    cy: computedSizeQuarter,
    fill: "none",
    stroke: theme.backgroundFiller,
    strokeWidth: strokeWidth$,
    strokeLinecap: "round",
    strokeDasharray: Math.PI * computedRadius
  }, hasAnimation && _react.default.createElement("animateTransform", {
    attributeName: "transform",
    type: "rotate",
    calcMode: "linear",
    values: "0 ".concat(computedSizeQuarter, " ").concat(computedSizeQuarter, ";360 ").concat(computedSizeQuarter, ", ").concat(computedSizeQuarter),
    keyTimes: "0;1",
    dur: theme.transitionSpeedSpinner,
    begin: "0s",
    repeatCount: "indefinite"
  })));
};

Spinner.propTypes = {
  /**
   * Spinner theme
   */
  theme: _propTypes.default.object.isRequired,

  /**
   * Define Spinner size
   */
  size: _propTypes.default.oneOfType([_propTypes.default.number, _propTypes.default.oneOf(Object.values(_oxygenConstants.spinnerSize))]),

  /**
   * Define Spinner stroke size
   */
  strokeWidth: _propTypes.default.number,

  /**
   * Tell when Spinner is animated
   */
  hasAnimation: _propTypes.default.bool
};
Spinner.defaultProps = {
  size: 'default',
  strokeWidth: undefined,
  hasAnimation: true
};

var _default = (0, _oxygenConfig.withTheme)(Spinner, _themeName.default);

exports.default = _default;