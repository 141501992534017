"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.radioGroup = exports.radio = void 0;

var _size = _interopRequireDefault(require("../choices/size.const"));

var _color = _interopRequireDefault(require("../choices/color.const"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var radio = {
  borderSize: _size.default.borderSizeDefault,
  borderStyle: 'solid',
  iconSize: _size.default.default2x,
  iconDotSize: _size.default.default,
  iconSelectedColor: _color.default.dodgerBlue,
  iconBackgroundColor: _color.default.white,
  iconDisabledColor: _color.default.cloudyBlue100,
  iconDisabledAccentColor: _color.default.cloudyBlue,
  iconSpacing: _size.default.default,
  labelColor: _color.default.pickledBluewood800,
  labelHeight: _size.default.medium
};
exports.radio = radio;
var radioGroup = {
  margin: _size.default.default
};
exports.radioGroup = radioGroup;