"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.list = void 0;

var _font = _interopRequireDefault(require("../choices/font.const"));

var _size = _interopRequireDefault(require("../choices/size.const"));

var _color = _interopRequireDefault(require("../choices/color.const"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var list = {
  listPadding: '0',
  listMargin: '0',
  listStyle: 'none',
  listGroupBorderColor: _color.default.cloudyBlue300,
  listBackground: '#FFF',
  listMaxWidth: '100%',
  listItemFontFamily: _font.default.family,
  listItemFontSize: _font.default.sizeDefault,
  listItemFontWeight: _font.default.weightDefault,
  listItemLineHeight: _font.default.lineHeightMedium,
  listItemPadding: "2px ".concat(_size.default.default),
  listItemColor: _color.default.pickledBluewood800,
  listItemActiveTextDecoration: 'none',
  listItemColorActive: _color.default.dodgerBlue,
  listItemColorDisabled: _color.default.steel700
};
exports.list = list;
var _default = list;
exports.default = _default;