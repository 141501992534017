"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RadioInput = exports.RadioIcon = exports.RadioContainer = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  label {\n    white-space: normal;\n    text-align: left;\n    display: flex;\n    align-items: flex-start;\n    color: ", ";\n  }\n  & > div {\n    height: auto;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  opacity: 0;\n  cursor: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  box-sizing: border-box;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: ", ";\n  min-width: ", ";\n  max-width: ", ";\n  background: ", ";\n  color: ", ";\n  border-radius: 100%;\n  border: ", ";\n  margin-right: ", ";\n  ", "\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var getRadioBackgroundColor = function getRadioBackgroundColor(props) {
  var theme = props.theme,
      isChecked = props.isChecked,
      isDisabled = props.isDisabled;

  if (isDisabled) {
    return theme.iconDisabledColor;
  }

  return isChecked ? null : theme.iconBackgroundColor;
};

var getRadioButtonDot = function getRadioButtonDot(props) {
  var isChecked = props.isChecked,
      theme = props.theme;

  if (isChecked) {
    return "\n      &::after {\n        width: ".concat(theme.iconDotSize, ";\n        height: ").concat(theme.iconDotSize, ";\n        border-radius: 100%;\n        background: currentColor;\n        visibility: visible;\n        content: '';\n    ");
  }

  return null;
};

var RadioIcon = _styledComponents.default.div(_templateObject(), function (_ref) {
  var theme = _ref.theme;
  return theme.iconSize;
}, function (props) {
  return props.theme.iconSize;
}, function (props) {
  return props.theme.iconSize;
}, function (props) {
  return getRadioBackgroundColor(props);
}, function (props) {
  return props.isChecked && !props.isDisabled ? props.theme.iconSelectedColor : props.theme.iconDisabledAccentColor;
}, function (props) {
  return "".concat(props.theme.borderSize, "\n    ").concat(props.theme.borderStyle, "\n    currentColor\n  ");
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.iconSpacing;
}, function (_ref3) {
  var label = _ref3.label,
      theme = _ref3.theme;
  return label ? "margin-top: calc(".concat(theme.labelHeight, " / 2 - ").concat(theme.iconSize, " / 2);") : null;
}, function (props) {
  return getRadioButtonDot(props);
});

exports.RadioIcon = RadioIcon;

var RadioInput = _styledComponents.default.input(_templateObject2(), function (_ref4) {
  var disabled = _ref4.disabled;
  return disabled ? 'default' : 'pointer';
});

exports.RadioInput = RadioInput;

var RadioContainer = _styledComponents.default.div(_templateObject3(), function (props) {
  return props.isDisabled ? props.theme.iconDisabledAccentColor : props.theme.labelColor;
});

exports.RadioContainer = RadioContainer;