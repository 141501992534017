"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _size = _interopRequireDefault(require("../choices/size.const"));

var _font = _interopRequireDefault(require("../choices/font.const"));

var _color = _interopRequireDefault(require("../choices/color.const"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var modalHeader = {
  padding: _size.default.default2x,
  paddingRightWithButton: "".concat(parseInt(_size.default.medium, 10) + 2 * parseInt(_size.default.default2x, 10), "px"),
  shadowSize: _size.default.shadowSmall,
  shadowColor: _color.default.cloudyBlue300,
  titleFontSize: _font.default.sizeLarge,
  titleLineHeight: _font.default.lineHeightDefault,
  titleFontWeight: _font.default.weightAttention,
  fontFamily: _font.default.family
};
var _default = modalHeader;
exports.default = _default;