import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import queryString from 'query-string';

// components
import { Header } from '../../components/header';
import { MainFrame } from '../../components/mainFrame';
import { Loading } from '../loading';
import { Store } from '../../store';

// custom style
import style from './successPage.module.scss';

export const Success = () => {
  const { state: { successPage }, dispatch } = useContext(Store);
  const [loading, setLoading] = useState(false);
  const Queryvalues = queryString.parse(window.location.search);

  useEffect(() => {
    async function fetchThankyouPage() {
      try {
        setLoading(true);
        const response = await axios.get('/api/success');
        dispatch({
          type: 'SUCCESS_PAGE',
          payload: response.data,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    fetchThankyouPage();
  }, [dispatch]);

  return (
    loading ? <Loading /> : (
      <>
        <Header />
        <MainFrame>
          <div className={style.successPageWrapper}>
            {
              successPage && successPage.entries[0]
              && (
                <div
                  className={style.content}
                >
                  <p>{`${successPage?.entries[0]?.success_message} ${Queryvalues?.invoiceId ? Queryvalues?.invoiceId : ''}.`}</p>
                </div>
              )
            }
          </div>
        </MainFrame>
      </>
    )
  );
};
