"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-family: ", ";\n  font-size: ", ";\n  font-stretch: ", ";\n  font-style: ", ";\n  font-weight: ", ";\n  letter-spacing: ", ";\n  line-height: ", ";\n  text-align: center;\n  width: 100%;\n  color: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var ProgressBarText = _styledComponents.default.div(_templateObject(), function (_ref) {
  var theme = _ref.theme;
  return theme.fontFamily;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.fontSize;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.fontStretch;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.fontStyle;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.fontWeight;
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.letterSpacing;
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.lineHeight;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.color;
});

var _default = ProgressBarText;
exports.default = _default;