import React, { useEffect, useContext, useState, useRef } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import Input from '@8x8/oxygen-input';
import Label from '@8x8/oxygen-label';
import Radio, { RadioGroup } from '@8x8/oxygen-radio';
import Button from '@8x8/oxygen-button';

// components
import { Header } from '../../components/header';
import { MainFrame } from '../../components/mainFrame';
import { Loading } from '../loading';
import { Store } from '../../store';
import { commaFormatted } from '../../utils';

// custom style
import style from './payNow.module.scss';

let requestId
let ipAddress
export const PayNow = ({ history }) => {
  const iframeRef = useRef();
  const { state: { paynowPage, reqPayment }, dispatch } = useContext(Store);
  const [loading, setLoading] = useState(false);
  const [radioValue, setRadioValue] = useState({ method: '' });
  const [disabled, setDisabled] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [CC, setCC] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState();
  const { country, currencyCode, source, externalTransactionId, accountId, externalRequestId, requestType } = queryString.parse(window.location.search);

  useEffect(() => {
    const queryStringData = queryString.parse(window.location.search);
    (async () => {
      try {
          const res = await axios.get('https://api.ipify.org');
          ipAddress = res.data;
        } catch (err) {
          console.error(err.response.data, 'https://api.ipify.org');
        }

      try {
        setLoading(true);
        let response;
        try {
          response = await axios.get('/api/paynow');
          dispatch({
            type: 'PAYNOW_PAGE',
            payload: response.data,
          });
        } catch (err) {
          history.push('/problem');
        }


        // Getting redirect link
        let redirectionLink;
        try {
          redirectionLink = await axios.get('/api/redirect');
        } catch (err) {
          history.push('/problem');
        }
        // Creating payment request
        try {
          let reqPaymentData
          if(queryStringData?.transactionId){
            reqPaymentData = await axios.get(`/api/paymentRequest/v2/${queryStringData.transactionId}` );
            const data = {
              ipAddress: ipAddress,
            };
            //invoke patch api for updating ip
            await axios.post(`/api/paymentRequest/v2/${queryStringData.transactionId}`, data );
          }
          else{
            const payData = {
              ...queryStringData,
              paymentAmount:
              {
                currencyCode: queryStringData?.currencyCode || null,
                totalAmount: queryStringData?.totalAmount || null,
              },
              requestType,
              onSuccessWebRedirectURL: `${redirectionLink?.data?.link}/success${queryStringData?.externalRequestId ? `?invoiceId=${queryStringData?.externalRequestId}`: ''}`,
              onFailureWebRedirectURL: `${redirectionLink?.data?.link}/failure`,
              ipAddress: ipAddress
            }
            if (payData?.totalAmount) {
              delete payData['totalAmount']
            }
            if (payData?.currencyCode) {
              delete payData['currencyCode']
            }
            reqPaymentData = await axios.post('/api/paymentRequest/v2', payData);
          }
          requestId = reqPaymentData.data.id;
          dispatch({
            type: 'PAY_REQUEST',
            payload: reqPaymentData.data,
          });
        } catch (err) {
          history.push('/problem');
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        history.push('/problem');
      }

    })()
  }, [
    country,
    currencyCode,
    source,
    externalTransactionId,
    accountId,
    requestType,
    dispatch,
    externalRequestId,
    history,
  ]);

  useEffect(() => {
    if (paynowPage && reqPayment) {
      const paymentOptions = (pageData, apiData) => {
        const paymentOption = apiData.paymentMethods.map((val) => ({
          method: val.method,
          paymentGatewayRedirectUrl: val.paymentGatewayRedirectUrl,
          methodName: (apiData.country === 'GBR' && val.method === 'ACH') ? (
            pageData?.entries[0]?.payment_method_group?.find((pageVal) => pageVal.method === 'DD').payment_method_name
          ) : (
            pageData?.entries[0]?.payment_method_group?.find((pageVal) => pageVal.method === val.method).payment_method_name
          )
        }));
        return paymentOption;
      }
      setPaymentMethod(paymentOptions(paynowPage, reqPayment))
    }
  }, [paynowPage, reqPayment])

  const updatePreference = async (value) => {
    setRadioValue(value);
    setDisabledButton(false);
  };

  const continueButton = () => {
    if (radioValue.method === 'CC') {
      setDisabled(true);
      setCC(true);
    } else if (radioValue.method === 'ACH') {
      window.location.replace(radioValue.paymentGatewayRedirectUrl);
    }
  };

  const handleCheckbox = async (e) => {
    let checkBoxFIeld = {}
    if(e.target.name === "isPrimary" ){
      checkBoxFIeld = {
        "ipAddress": ipAddress,
        "isPrimary": e.target.checked
      }
    }else if(e.target.name === "savePaymentMethod"){
      checkBoxFIeld = {
        "ipAddress": ipAddress,
        "savePaymentMethod": e.target.checked
      }
    }
    await axios.post(`/api/paymentRequest/v2/${requestId}`, checkBoxFIeld );
  };

  return (
    loading ? <Loading className={style.loading} /> : (
      <>
        <Header />
        <MainFrame>
          <div className={style.payNowWrapper}>
            {
              paynowPage &&
              paynowPage?.entries[0] &&
              reqPayment?.accountId &&
              reqPayment?.user?.company &&
              reqPayment?.user?.company.trim() !== "" &&
              <div className={style.textBox}>
                <Label value={paynowPage?.entries[0]?.group.account_name} />
                <Input type="text" value={reqPayment?.user?.company} isDisabled />
              </div>
            }
            {
              paynowPage &&
              paynowPage?.entries[0] &&
              reqPayment?.externalRequestId &&
              reqPayment?.externalRequestId.trim() !== "" &&
              <div className={style.textBox}>
                <Label value={paynowPage?.entries[0]?.group.invoice_number} />
                <Input type="text" value={reqPayment?.externalRequestId} isDisabled />
              </div>
            }
            {
              paynowPage &&
              paynowPage?.entries[0] &&
              reqPayment?.paymentAmount.totalAmount !== null &&
              reqPayment?.paymentAmount.totalAmount !== undefined &&
              Number(reqPayment?.paymentAmount?.totalAmount) > 0 &&
              <div className={style.textBox}>
                <Label value={paynowPage?.entries[0]?.group.amount} />
                <Input
                  type="text"
                  value={`${ (reqPayment?.paymentAmount?.currencyCode === 'USD' || reqPayment?.paymentAmount?.currencyCode === 'CAD') ? paynowPage?.entries[0]?.currency_code_symbol?.symbol[0] : paynowPage?.entries[0]?.currency_code_symbol?.symbol[1]} ${commaFormatted(reqPayment?.paymentAmount?.totalAmount)}`}
                  isDisabled
                />
              </div>
            }
            <div>
              <RadioGroup className={`${style.radioGroup} ${style.spacing}`} value={radioValue.method}>
              {
                paymentMethod && (
                  paymentMethod?.map((val) => (
                    <Radio
                      key={uuidv4()}
                      label={val.methodName}
                      value={val.method}
                      isDisabled={(disabled && val.method === 'ACH')}
                      onClick={() => { updatePreference(val)}}
                    />
                  ))
                )
              }
            </RadioGroup>
            </div>
            { (reqPayment?.showPrimaryFlagSelector || reqPayment?.showSavePaymentSelector) &&
              <div className={style.divOutline}>
              <p><strong>{paynowPage?.entries[0]?.paid_save_payment_method ? paynowPage.entries[0].paid_save_payment_method.payment_method_header : null}</strong></p>
              {
                reqPayment?.showSavePaymentSelector &&
                <div>
                  <p className={style.checkBoxText}>
                    <input type="checkbox" onChange={handleCheckbox} name="savePaymentMethod" defaultChecked={reqPayment?.savePaymentMethod}/>
                    {paynowPage?.entries[0]?.paid_save_payment_method ? paynowPage.entries[0].paid_save_payment_method.save_in_8x8_account_text : null}
                  </p>
                </div>
              }
              { reqPayment?.showPrimaryFlagSelector &&
                <div>
                  <p className={style.checkBoxText}>
                    <input type="checkbox" onChange={handleCheckbox} name="isPrimary" defaultChecked={reqPayment?.isPrimary}/>
                   {paynowPage?.entries[0]?.paid_save_payment_method ? paynowPage.entries[0].paid_save_payment_method.automatically_charge_the_account_balance : null}
                  </p>
                </div>
              }
            </div>
            }
            {
              paynowPage &&
              paynowPage?.entries[0] &&
              <div className={style.confirmButton}>
                <Button
                  isDisabled={disabledButton}
                  onClick={continueButton}
                >
                  {paynowPage?.entries[0]?.payment_continue_cta}
                </Button>
              </div>
            }
            {
              radioValue?.method === 'CC' &&
              CC &&
              <div className={style.iframe}>
                <iframe
                  src={reqPayment?.paymentMethods[0]?.paymentGatewayRedirectUrl}
                  title="iframe"
                  id="frameSize"
                  ref={iframeRef}
                  width="100%"
                  height="630px"
                  frameBorder="0"
                  sandbox="allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-top-navigation"
                />
              </div>
            }
          </div>
        </MainFrame>
      </>
    )
  );
};
