"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SecondaryContainer = exports.PrimaryContainer = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _isUndefined = _interopRequireDefault(require("lodash/isUndefined"));

var _oxygenConstants = require("@8x8/oxygen-constants");

var config = _interopRequireWildcard(require("@8x8/oxygen-config"));

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n\n  &:focus,\n  &:hover {\n    background: ", ";\n    border-color: ", ";\n  }\n\n  &:active {\n    background: ", ";\n    border-color: ", ";\n  }\n\n  &[disabled] {\n    background: ", ";\n    border-color: ", ";\n    color: ", ";\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  cursor: pointer;\n  white-space: nowrap;\n  vertical-align: middle;\n  user-select: none;\n  outline: none;\n  transition: background-color ", "\n    ease-in-out;\n  border: ", " solid;\n  border-radius: ", ";\n  font-size: ", ";\n  line-height: ", ";\n  height: ", ";\n  padding: ", ";\n  color: ", ";\n  ", ";\n  ", ";\n  font-family: ", ";\n  font-weight: ", ";\n\n  &:focus,\n  &:hover {\n    background: ", ";\n    border-color: ", ";\n  }\n\n  &:active {\n    background: ", ";\n    border-color: ", ";\n  }\n\n  &[disabled] {\n    background: ", ";\n    border-color: ", ";\n    color: ", ";\n    cursor: default;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

/**
 * Return the main colors of the button based on active state and button type
 * @param {Object} props
 * @param {Boolean} primary
 * @return {Object}
 */
function getButtonMainColors(props, primary) {
  var isActive = props.isActive,
      theme = props.theme;

  if (isActive) {
    return {
      background: primary ? theme.backgroundColorPrimaryActive : theme.backgroundColorSecondaryActive,
      'border-color': primary ? theme.borderColorPrimaryActive : theme.borderColorSecondaryActive
    };
  }

  return {
    background: primary ? theme.backgroundColorPrimary : theme.backgroundColorSecondary,
    'border-color': primary ? theme.borderColorPrimary : theme.borderColorSecondary
  };
}
/**
 * Return the button shadow based on props
 * @param {Object} props
 * @return {String}
 */


function getButtonBoxShadow(props) {
  var theme = props.theme;
  var hasShadow = (0, _isUndefined.default)(props.hasShadow) ? theme.hasShadow : props.hasShadow;
  return "box-shadow: ".concat(hasShadow ? "".concat(theme.shadowSize, " ").concat(theme.shadowColor) : 'none');
}

var PrimaryContainer = _styledComponents.default.button.attrs(function (_ref) {
  var testId = _ref.testId;
  return _objectSpread({}, config.getTestAttributes(testId));
})(_templateObject(), function (_ref2) {
  var theme = _ref2.theme;
  return theme.transitionDuration;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.borderSize;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.borderRadius;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.fontSize;
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.lineHeight;
}, function (_ref7) {
  var theme = _ref7.theme,
      size = _ref7.size;
  return size === _oxygenConstants.buttonSize.big ? theme.heightLarge : theme.heightSmall;
}, function (_ref8) {
  var theme = _ref8.theme,
      size = _ref8.size;
  return size === _oxygenConstants.buttonSize.big ? theme.paddingLarge : theme.paddingSmall;
}, function (_ref9) {
  var theme = _ref9.theme;
  return theme.fontColorPrimary;
}, function (props) {
  return getButtonMainColors(props, true);
}, function (props) {
  return getButtonBoxShadow(props);
}, function (_ref10) {
  var theme = _ref10.theme;
  return theme.fontFamily;
}, function (_ref11) {
  var theme = _ref11.theme;
  return theme.fontWeight;
}, function (_ref12) {
  var theme = _ref12.theme;
  return theme.backgroundColorPrimaryHover;
}, function (_ref13) {
  var theme = _ref13.theme;
  return theme.borderColorPrimaryHover;
}, function (_ref14) {
  var theme = _ref14.theme;
  return theme.backgroundColorPrimaryActive;
}, function (_ref15) {
  var theme = _ref15.theme;
  return theme.borderColorPrimaryActive;
}, function (_ref16) {
  var theme = _ref16.theme;
  return theme.backgroundColorPrimaryDisabled;
}, function (_ref17) {
  var theme = _ref17.theme;
  return theme.borderColorPrimaryDisabled;
}, function (_ref18) {
  var theme = _ref18.theme;
  return theme.fontColorPrimaryDisabled;
});

exports.PrimaryContainer = PrimaryContainer;
PrimaryContainer.propTypes = {
  isActive: _propTypes.default.bool,
  size: _propTypes.default.oneOf([_oxygenConstants.buttonSize.small, _oxygenConstants.buttonSize.big]),
  hasShadow: _propTypes.default.bool
};
var SecondaryContainer = (0, _styledComponents.default)(PrimaryContainer)(_templateObject2(), function (_ref19) {
  var theme = _ref19.theme;
  return theme.fontColorSecondary;
}, function (props) {
  return getButtonMainColors(props, false);
}, function (_ref20) {
  var theme = _ref20.theme;
  return theme.backgroundColorSecondaryHover;
}, function (_ref21) {
  var theme = _ref21.theme;
  return theme.borderColorSecondaryHover;
}, function (_ref22) {
  var theme = _ref22.theme;
  return theme.backgroundColorSecondaryActive;
}, function (_ref23) {
  var theme = _ref23.theme;
  return theme.borderColorSecondaryActive;
}, function (_ref24) {
  var theme = _ref24.theme;
  return theme.backgroundColorSecondaryDisabled;
}, function (_ref25) {
  var theme = _ref25.theme;
  return theme.borderColorSecondaryDisabled;
}, function (_ref26) {
  var theme = _ref26.theme;
  return theme.fontColorSecondaryDisabled;
});
exports.SecondaryContainer = SecondaryContainer;