import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Spinner } from '@8x8/oxygen-loaders';

// custom css
import styles from './loading.module.scss';

// components
import { Header } from '../../components/header';
import { MainFrame } from '../../components/mainFrame';

export const Loading = ({
  loadingMsg, header, style, className,
}) => {
  const classes = classnames(styles.loadingWrapper, className);
  return (
    <div className={classes} style={style}>
      { header ? (
        <Header />
      ) : null }
      <MainFrame className={styles.spinnerPosition}>
        <Spinner />
        <p>{loadingMsg || ''}</p>
      </MainFrame>
    </div>
  );
};

Loading.defaultProps = {
  header: true,
};

Loading.propTypes = {
  loadingMsg: PropTypes.string,
  header: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
};
