"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Label = Label;
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = require("styled-components");

var _oxygenConfig = require("@8x8/oxygen-config");

var _themeName = _interopRequireDefault(require("../constants/themeName"));

var _targets = require("../constants/targets");

var _Icon = _interopRequireDefault(require("./Icon"));

var _Value = _interopRequireDefault(require("./Value"));

var _Action = _interopRequireDefault(require("./Action"));

var _LabelWrapper = _interopRequireDefault(require("../styled/LabelWrapper"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function Label(_ref) {
  var theme = _ref.theme,
      children = _ref.children,
      className = _ref.className,
      style = _ref.style,
      value = _ref.value,
      action = _ref.action,
      actionLabel = _ref.actionLabel,
      actionTarget = _ref.actionTarget,
      htmlFor = _ref.htmlFor,
      iconText = _ref.iconText,
      required = _ref.required,
      disabled = _ref.disabled,
      showTooltipOnOverflow = _ref.showTooltipOnOverflow;
  var showAction = action || actionLabel;
  return _react.default.createElement(_styledComponents.ThemeProvider, {
    theme: theme
  }, _react.default.createElement(_LabelWrapper.default, {
    style: style,
    className: className,
    value: value
  }, value && _react.default.createElement(_Value.default, {
    theme: theme,
    value: value,
    htmlFor: htmlFor,
    required: required,
    disabled: disabled,
    showTooltipOnOverflow: showTooltipOnOverflow
  }), iconText && _react.default.createElement(_Icon.default, {
    iconText: iconText,
    theme: theme
  }), showAction && _react.default.createElement(_Action.default, {
    action: action,
    actionTarget: actionTarget
  }, actionLabel), children));
}

Label.propTypes = {
  /**
   * Label theme
   */
  theme: _propTypes.default.object.isRequired,

  /**
   * Renders element just after value and action
   */
  children: _propTypes.default.node,

  /**
   * Adds css class to parent element
   */
  className: _propTypes.default.string,

  /**
   * Adds inline styles to parent element
   * @deprecated
   */
  style: _propTypes.default.object,

  /**
   * Renders label content (text or a component)
   */
  value: _propTypes.default.node,

  /**
   * Defines callback onClick event
   */
  action: _propTypes.default.oneOfType([_propTypes.default.func, _propTypes.default.string]),

  /**
   * Renders text inside action element
   */
  actionLabel: _propTypes.default.oneOfType([_propTypes.default.string, _propTypes.default.number]),

  /**
   * Specifies type of target on action element
   */
  actionTarget: _propTypes.default.oneOf([_targets.BLANK, _targets.SELF, _targets.PARENT, _targets.TOP]),

  /**
   * Specifies which form element a label is bound to
   */
  htmlFor: _propTypes.default.string,

  /**
   * Renders text inside Tooltip on Icon
   */
  iconText: _propTypes.default.string,

  /**
   * Tell when label is disabled
   */
  disabled: _propTypes.default.bool,

  /**
   * Tell when required sight is visible
   */
  required: _propTypes.default.bool,

  /**
   * Whether to show tooltip when text overflows label.
   */
  showTooltipOnOverflow: _propTypes.default.bool
};
Label.defaultProps = {
  children: null,
  className: undefined,
  style: undefined,
  value: '',
  action: '',
  actionLabel: '',
  actionTarget: null,
  htmlFor: null,
  iconText: undefined,
  disabled: false,
  required: false,
  showTooltipOnOverflow: true
};

var _default = (0, _oxygenConfig.withTheme)(Label, _themeName.default);

exports.default = _default;