"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buttonSize = exports.button = void 0;

var _color = _interopRequireDefault(require("../choices/color.const"));

var _timing = _interopRequireDefault(require("../choices/timing.const"));

var _font = _interopRequireDefault(require("../choices/font.const"));

var _size = _interopRequireDefault(require("../choices/size.const"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var button = {
  backgroundColorPrimary: _color.default.dodgerBlue,
  backgroundColorPrimaryHover: _color.default.dodgerBlue400,
  backgroundColorPrimaryActive: _color.default.dodgerBlue700,
  backgroundColorPrimaryDisabled: _color.default.dodgerBlue300,
  backgroundColorSecondary: _color.default.white,
  backgroundColorSecondaryHover: _color.default.cloudyBlue100,
  backgroundColorSecondaryActive: _color.default.cloudyBlue300,
  backgroundColorSecondaryDisabled: _color.default.white,
  borderColorPrimary: _color.default.dodgerBlue,
  borderColorPrimaryHover: _color.default.dodgerBlue400,
  borderColorPrimaryActive: _color.default.dodgerBlue700,
  borderColorPrimaryDisabled: _color.default.dodgerBlue300,
  borderColorSecondary: _color.default.cloudyBlue300,
  borderColorSecondaryHover: _color.default.cloudyBlue300,
  borderColorSecondaryActive: _color.default.cloudyBlue300,
  borderColorSecondaryDisabled: _color.default.cloudyBlue300,
  fontColorPrimary: _color.default.white,
  fontColorPrimaryDisabled: _color.default.dodgerBlue100,
  fontColorSecondary: _color.default.pickledBluewood800,
  fontColorSecondaryDisabled: _color.default.cloudyBlue300,
  shadowSize: _size.default.shadowDefault,
  shadowColor: _color.default.cloudyBlue300,
  hasShadow: true,
  fontSize: _font.default.sizeDefault,
  lineHeight: _font.default.lineHeightMedium,
  fontFamily: _font.default.family,
  fontWeight: _font.default.weightAttention,
  paddingSmall: "calc(".concat(_size.default.small, " - 1px) calc(").concat(_size.default.default2x, " - 1px)"),
  paddingLarge: "calc(".concat(_size.default.default, " - 1px) calc(").concat(_size.default.medium, " - 1px)"),
  borderSize: _size.default.borderSizeDefault,
  borderRadius: _size.default.borderRadiusDefault,
  transitionDuration: _timing.default.fast,
  heightSmall: _size.default.formItemSizeSmall,
  heightLarge: _size.default.formItemSizeLarge,
  marginLeftSmall: _size.default.default,
  marginLeftLarge: _size.default.default2x
};
exports.button = button;
var buttonSize = {
  small: 'small',
  big: 'big'
};
exports.buttonSize = buttonSize;