"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _polished = require("polished");

var _styledComponents = require("styled-components");

var _color = _interopRequireDefault(require("../choices/color.const"));

var _font = _interopRequireDefault(require("../choices/font.const"));

var _size = _interopRequireDefault(require("../choices/size.const"));

var _timing = _interopRequireDefault(require("../choices/timing.const"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  animation: ", " ", " ease-in;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  0% {\n    opacity: 0;\n    transform: translateY(-10px);\n  }\n  70% {\n    opacity: 0;\n    transform: translateY(-10px);\n  }\n  100% {\n    opacity: 1;\n    transform: translateY(0);\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var animation = (0, _styledComponents.keyframes)(_templateObject());
var fadeInAnimation = (0, _styledComponents.css)(_templateObject2(), animation, _timing.default.fast);
var staticTooltip = {
  animation: fadeInAnimation,
  backgroundColor: _color.default.cloudyBlue100,
  borderWidth: _size.default.borderSizeDefault,
  borderStyle: 'solid',
  borderColor: _color.default.cloudyBlue300,
  borderRadius: _size.default.borderRadiusSmall,
  boxShadow: "1px 1px 0 ".concat((0, _polished.rgba)(_color.default.pickledBluewood800, 0.1)),
  color: _color.default.pickledBluewood800,
  fontSize: _font.default.sizeSmall,
  fontFamily: _font.default.family,
  lineHeight: _font.default.lineHeightDefault,
  margin: _size.default.small,
  maxHeight: '126px',
  maxWidth: '256px',
  padding: _size.default.default
};
var _default = staticTooltip;
exports.default = _default;