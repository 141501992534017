"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _polished = require("polished");

var _styledComponents = require("styled-components");

var _color = _interopRequireDefault(require("../choices/color.const"));

var _timing = _interopRequireDefault(require("../choices/timing.const"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  animation: ", " ", " ease-in;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["from { opacity: 0; } to { opacity: 1; }"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var animation = (0, _styledComponents.keyframes)(_templateObject());
var fadeInAnimation = (0, _styledComponents.css)(_templateObject2(), animation, _timing.default.fast);
var overlay = {
  backgroundColor: (0, _polished.rgba)(_color.default.cloudyBlue100, 0.75),
  animation: fadeInAnimation
};
var _default = overlay;
exports.default = _default;