"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var font = {
  sizeSmall: '13px',
  sizeDefault: '15px',
  sizeMedium: '19px',
  sizeLarge: '24px',
  sizeAttention: '36px',
  sizeMax: '45px',
  weightDefault: 400,
  weightAttention: 600,
  weightAttentionMax: 700,
  lineHeightSmall: 1,
  lineHeightDefault: 1.4,
  lineHeightMedium: 1.6,

  /* eslint-disable */
  family: '\'Nunito\', \'Helvetica Neue\', Helvetica, Arial, sans-serif'
  /* eslint-enable */

};
var _default = font;
exports.default = _default;