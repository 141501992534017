"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.tabs = void 0;

var _color = _interopRequireDefault(require("../choices/color.const"));

var _size = _interopRequireDefault(require("../choices/size.const"));

var _font = _interopRequireDefault(require("../choices/font.const"));

var _timing = _interopRequireDefault(require("../choices/timing.const"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var tabs = {
  tabTextFontFamily: _font.default.family,
  tabTextFontSize: _font.default.sizeDefault,
  tabTextFontWeight: _font.default.weightDefault,
  tabTextLineHeight: _font.default.lineHeightMedium,
  tabTextColorDark: _color.default.cloudyBlue,
  tabTextColorDarkHover: _color.default.white,
  tabTextColorDarkActive: _color.default.white,
  tabTextColorDarkDisabled: _color.default.steel700,
  tabTextColorLight: _color.default.pickledBluewood800,
  tabTextColorLightHover: _color.default.dodgerBlue,
  tabTextColorLightActive: _color.default.dodgerBlue,
  tabTextColorLightDisabled: _color.default.cloudyBlue,
  tabHeight: _size.default.large,
  tabMaxWidth: 'initial',
  tabMinWidth: 'initial',
  tabPadding: "0 ".concat(_size.default.default2x),
  tabBorderRadius: 0,
  tabBorderTopSize: 0,
  tabBorderRightSize: 0,
  tabBorderBottomSize: _size.default.small,
  tabBorderLeftSize: 0,
  tabBorderColorDark: 'transparent',
  tabBorderColorDarkHover: 'transparent',
  tabBorderColorDarkActive: _color.default.goldenBell,
  tabBorderColorDarkDisabled: 'transparent',
  tabBorderColorLight: 'transparent',
  tabBorderColorLightHover: 'transparent',
  tabBorderColorLightActive: _color.default.dodgerBlue,
  tabBorderColorLightDisabled: 'transparent',
  tabBackgroundDark: 'transparent',
  tabBackgroundDarkHover: 'transparent',
  tabBackgroundDarkActive: 'transparent',
  tabBackgroundDarkDisabled: 'transparent',
  tabBackgroundLight: 'transparent',
  tabBackgroundLightHover: 'transparent',
  tabBackgroundLightActive: 'transparent',
  tabBackgroundLightDisabled: 'transparent',
  tabTransitionTime: _timing.default.fast,
  tabsBarBackgroundDark: _color.default.pickledBluewood,
  tabsBarBackgroundLight: _color.default.white,
  tabsBarBorderColorDark: _color.default.pickledBluewood,
  tabsBarBorderColorLight: _color.default.cloudyBlue300,
  tabsBarBorderTopSize: 0,
  tabsBarBorderRightSize: 0,
  tabsBarBorderBottomSize: '1px',
  tabsBarBorderLeftSize: 0,
  tabsBarPadding: "0 ".concat(_size.default.medium),
  tabsBarBorderRadius: 0
};
exports.tabs = tabs;
var _default = tabs;
exports.default = _default;