/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import HtmlParser from 'react-html-parser';
import Button from '@8x8/oxygen-button';
import style from './errorPage.module.scss';
import { Header } from '../../components/header';
import { MainFrame } from '../../components/mainFrame';
import { Loading } from '../loading';
import { Store } from '../../store';

export const ErrorPage = () => {
  const { state: { errorPage }, dispatch } = useContext(Store);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function fetchErrorPage() {
      try {
        setLoading(true);
        const response = await axios.get('/api/error');
        dispatch({
          type: 'ERROR_PAGE',
          payload: response.data,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }());
  }, [dispatch]);

  return (
    loading ? <Loading /> : (
      <>
        <Header />
        <div className={style.errorWrapper}>
          <MainFrame>
            <div>
              <h5 className={style.heading}>{errorPage && errorPage.entries[0].reference[0].label_desktop_version}</h5>
              <div className={style.content}>{HtmlParser(errorPage && errorPage.entries[0].error_description)}</div>
              <div className={style.buttonPosition}>
                {
                  errorPage
                  && <Button onClick={() => { window.location.href = 'https://www.8x8.com/'; }}>{errorPage.entries[0].cta_reference[0].cta_label}</Button>
                }
              </div>
            </div>
          </MainFrame>
        </div>
      </>
    )
  );
};
