"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var timing = {
  fast: '150ms',
  medium: '300ms',
  slow: '900ms'
};
var _default = timing;
exports.default = _default;