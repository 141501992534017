"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toastSizeToWidthMap = exports.toastTypes = exports.toastSizes = exports.toast = void 0;

var _polished = require("polished");

var _color = _interopRequireDefault(require("../choices/color.const"));

var _font = _interopRequireDefault(require("../choices/font.const"));

var _size = _interopRequireDefault(require("../choices/size.const"));

var _timing = _interopRequireDefault(require("../choices/timing.const"));

var _toastSizeToWidthMap;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var verticalPaddingOffset = 4;
var toast = {
  toastSmall: '368px',
  toastMedium: '480px',
  toastLarge: '800px',
  animationEnterDuration: parseInt(_timing.default.medium, 10),
  animationEnterTimingFunction: 'easeIn',
  animationExitDuration: parseInt(_timing.default.fast, 10),
  animationExitTimingFunction: 'easeOut',
  animationFlipDuration: parseInt(_timing.default.fast, 10),
  backgroundColor: _color.default.steel700,
  backgroundColorError: _color.default.goldenBell,
  borderRadius: _size.default.borderRadiusDefault,
  boxShadow: "0 1px 5px 0 ".concat((0, _polished.rgba)(_color.default.pickledBluewood800, 0.2)),
  fontFamily: _font.default.family,
  fontSize: _font.default.sizeDefault,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: _font.default.weightAttention,
  color: _color.default.white,
  colorError: _color.default.pickledBluewood800,
  letterSpacing: 'normal',
  lineHeight: _font.default.lineHeightMedium,
  marginControl: '12px',
  textAlign: 'left',
  paddingX: _size.default.default2x,
  paddingY: "".concat(parseInt(_size.default.default2x, 10) - verticalPaddingOffset, "px"),
  maxHeight: '128px',
  maxWidth: '100%',
  stackMargin: '16px',
  actionsMarginLeft: '16px',
  actionsMarginRight: '4px',
  actionMarginRight: '16px'
};
exports.toast = toast;
var toastSizes = {
  small: 'small',
  medium: 'medium',
  large: 'large'
};
exports.toastSizes = toastSizes;
var toastTypes = {
  success: 'success',
  error: 'error'
};
exports.toastTypes = toastTypes;
var toastSizeToWidthMap = (_toastSizeToWidthMap = {}, _defineProperty(_toastSizeToWidthMap, toastSizes.small, toast.toastSmall), _defineProperty(_toastSizeToWidthMap, toastSizes.medium, toast.toastMedium), _defineProperty(_toastSizeToWidthMap, toastSizes.large, toast.toastLarge), _toastSizeToWidthMap);
exports.toastSizeToWidthMap = toastSizeToWidthMap;