"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var getColor = function getColor(_ref) {
  var theme = _ref.theme,
      fixed = _ref.fixed,
      disabled = _ref.disabled;

  if (fixed) {
    return theme.colorFixed;
  }

  if (disabled) {
    return theme.colorDisabled;
  }

  return theme.color;
};

var _default = getColor;
exports.default = _default;