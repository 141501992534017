"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = require("styled-components");

var _oxygenConfig = require("@8x8/oxygen-config");

var _oxygenConstants = require("@8x8/oxygen-constants");

var _themeName = _interopRequireDefault(require("../constants/themeName"));

var _Bar = require("../styled/Bar");

var _ProgressBarText = _interopRequireDefault(require("../styled/ProgressBarText"));

var _ProgressBarContainer = _interopRequireDefault(require("../styled/ProgressBarContainer"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ProgressBar = function ProgressBar(_ref) {
  var theme = _ref.theme,
      value = _ref.value,
      size = _ref.size,
      text = _ref.text,
      fullWidth = _ref.fullWidth;
  return _react.default.createElement(_styledComponents.ThemeProvider, {
    theme: theme
  }, _react.default.createElement(_ProgressBarContainer.default, {
    size: size,
    fullWidth: fullWidth
  }, _react.default.createElement(_Bar.Bar, {
    value: value,
    max: 100
  }, _react.default.createElement(_Bar.FallbackBar, null, _react.default.createElement(_Bar.FallbackBarContainer, null, _react.default.createElement(_Bar.FallbackBarProgress, {
    style: {
      width: "".concat(value, "%")
    }
  })))), text && _react.default.createElement(_ProgressBarText.default, null, text)));
};

ProgressBar.propTypes = {
  /**
   * ProgressBar theme
   */
  theme: _propTypes.default.object.isRequired,

  /**
   * Percentage of completed progress
   */
  value: _propTypes.default.number,

  /**
   * Define ProgressBar size
   */
  size: _propTypes.default.oneOfType([_propTypes.default.number, _propTypes.default.string, _propTypes.default.oneOf(Object.values(_oxygenConstants.progressBarSize))]),
  text: _propTypes.default.string,

  /**
   * Tell when ProgressBar is stretched to width
   */
  fullWidth: _propTypes.default.bool
};
ProgressBar.defaultProps = {
  value: 0,
  size: _oxygenConstants.progressBarSize.default,
  text: '',
  fullWidth: false
};

var _default = (0, _oxygenConfig.withTheme)(ProgressBar, _themeName.default);

exports.default = _default;