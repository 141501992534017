import 'react-app-polyfill/ie11';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { StoreProvider } from './store';

const MOUNT_NODE = document.getElementById('root');
const noop = () => {};

if (process.env.NODE_ENV === 'production') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

ReactDOM.render(<StoreProvider><BrowserRouter><App /></BrowserRouter></StoreProvider>, MOUNT_NODE);
