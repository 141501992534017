"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var getFont = function getFont(_ref) {
  var _ref$theme = _ref.theme,
      fontFamily = _ref$theme.fontFamily,
      fontSize = _ref$theme.fontSize,
      fontStretch = _ref$theme.fontStretch,
      fontStyle = _ref$theme.fontStyle,
      fontWeight = _ref$theme.fontWeight,
      letterSpacing = _ref$theme.letterSpacing,
      lineHeight = _ref$theme.lineHeight;
  return "\n  font-family: ".concat(fontFamily, ";\n  font-size: ").concat(fontSize, ";\n  font-stretch: ").concat(fontStretch, ";\n  font-style: ").concat(fontStyle, ";\n  font-weight: ").concat(fontWeight, ";\n  letter-spacing: ").concat(letterSpacing, ";\n  line-height: ").concat(lineHeight, ";\n");
};

var _default = getFont;
exports.default = _default;