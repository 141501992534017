"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.progressBarSize = exports.spinnerSize = exports.loaders = void 0;

var _color = _interopRequireDefault(require("../choices/color.const"));

var _font = _interopRequireDefault(require("../choices/font.const"));

var _size = _interopRequireDefault(require("../choices/size.const"));

var _timing = _interopRequireDefault(require("../choices/timing.const"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var loaders = {
  backgroundContainer: _color.default.cloudyBlue100,
  backgroundFiller: _color.default.dodgerBlue,
  borderSize: _size.default.borderSizeDefault,
  borderStyle: 'solid',
  borderColor: _color.default.cloudyBlue300,
  borderRadius: _size.default.borderSizeLarge,
  color: _color.default.pickledBluewood800,
  fontFamily: _font.default.family,
  fontSize: _font.default.sizeDefault,
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: _font.default.weightAttention,
  height: _size.default.default,
  lineHeight: _font.default.lineHeightMedium,
  letterSpacing: 'normal',
  transitionSpeedSpinner: _timing.default.slow,
  transitionSpeedProgressBar: _timing.default.medium,
  widthSpinnerSmall: parseInt(_size.default.medium, 10),
  widthSpinnerDefault: parseInt(_size.default.large, 10),
  widthSpinnerExtraLarge: parseInt(_size.default.large2x, 10),
  widthProgressBarSmall: '100px',
  widthProgressBarDefault: '240px',
  widthProgressBarLarge: '100%'
};
exports.loaders = loaders;
var spinnerSize = {
  small: 'small',
  default: 'default',
  large2x: 'large2x'
};
exports.spinnerSize = spinnerSize;
var progressBarSize = {
  small: 'small',
  default: 'default',
  large: 'large'
};
exports.progressBarSize = progressBarSize;