"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.set = set;
exports.reset = reset;
exports.get = get;
exports.getById = getById;
exports.getTestAttributes = getTestAttributes;
exports.withTheme = withTheme;

var _react = _interopRequireDefault(require("react"));

var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));

var _assign = _interopRequireDefault(require("lodash/assign"));

var _isObject = _interopRequireDefault(require("lodash/isObject"));

var _hoistNonReactStatics = _interopRequireDefault(require("hoist-non-react-statics"));

var constants = _interopRequireWildcard(require("@8x8/oxygen-constants"));

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var configObject = (0, _cloneDeep.default)(constants);
/**
 * Extend the default theme with custom configuration.
 * @param {Object} newConfigs
 */

function set(newConfigs) {
  for (var configName in newConfigs) {
    if (!Object.prototype.hasOwnProperty.call(newConfigs, configName)) {
      continue;
    }

    if ((0, _isObject.default)(configObject[configName]) && (0, _isObject.default)(newConfigs[configName])) {
      configObject[configName] = (0, _assign.default)(configObject[configName], newConfigs[configName]);
      continue;
    }

    configObject[configName] = newConfigs[configName];
  }
}
/**
 * Reset the configuration to use the default values for all components
 */


function reset() {
  configObject = (0, _cloneDeep.default)(constants);
}
/**
 * Get the full theme configuration
 * @return {Object}
 */


function get() {
  return (0, _cloneDeep.default)(configObject);
}
/**
 * Get the configuration for a component/element by ID
 * @param {String} configId
 * @return {*}
 */


function getById(configId) {
  return (0, _cloneDeep.default)(configObject[configId]);
}
/**
 * Get the test attributes object
 * @param getTestIdFn
 * @return {Object}
 */


function getTestAttributes(getTestIdFn) {
  return {
    'test-id': getTestIdFn,
    'data-test-id': getTestIdFn,
    'qr-walkme-id': getTestIdFn,
    'data-id': getTestIdFn
  };
}
/**
 *
 * @param {Class|Function} Component
 * @param {String} configProp Property name of configuration object
 */


function withTheme(Component, configProp) {
  var WithTheme = _react.default.forwardRef(function (props, ref) {
    var theme = props.theme,
        rest = _objectWithoutProperties(props, ["theme"]);

    var componentTheme = _objectSpread({}, getById(configProp), theme);

    return _react.default.createElement(Component, _extends({}, rest, {
      theme: componentTheme,
      forwardedRef: ref
    }));
  });

  (0, _hoistNonReactStatics.default)(WithTheme, Component);
  return WithTheme;
}