"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.badgePosition = exports.badgeSize = exports.badgeType = exports.badge = void 0;

var _color = _interopRequireDefault(require("../choices/color.const"));

var _size = _interopRequireDefault(require("../choices/size.const"));

var _font = _interopRequireDefault(require("../choices/font.const"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var badge = {
  sizeSmall: _size.default.default,
  sizeMedium: '18px',
  sizeBig: _size.default.medium,
  positionOuterSmall: '3px',
  positionOuterMedium: '8px',
  positionOuterBig: '10px',
  borderRadiusSmall: _size.default.small,
  borderRadiusMedium: '9px',
  borderRadiusBig: '12px',
  fontSizeSmall: 0,
  fontSizeMedium: _font.default.sizeSmall,
  fontSizeBig: _font.default.sizeSmall,
  lineHeightSmall: 0,
  lineHeightMedium: _font.default.lineHeightDefault,
  lineHeightBig: _font.default.lineHeightMedium,
  fontFamily: _font.default.family,
  paddingSmall: 0,
  paddingMedium: _size.default.small,
  paddingBig: _size.default.small,
  fontColorPrimary: _color.default.white,
  fontColorSecondary: _color.default.pickledBluewood800,
  backgroundColorPrimary: _color.default.coral400,
  backgroundColorSecondary: _color.default.goldenBell
};
exports.badge = badge;
var badgeType = {
  primary: 'primary',
  secondary: 'secondary'
};
exports.badgeType = badgeType;
var badgeSize = {
  small: 'small',
  medium: 'medium',
  big: 'big'
};
exports.badgeSize = badgeSize;
var badgePosition = {
  topLeft: 'tl',
  topRight: 'tr',
  bottomLeft: 'bl',
  bottomRight: 'br'
};
exports.badgePosition = badgePosition;