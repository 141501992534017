"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var tooltip = {
  delay: 0
};
var _default = tooltip;
exports.default = _default;