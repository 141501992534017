import React from 'react';
import style from './header.module.scss';

export const Header = () => {
  return (
    <div className={style.headerWraper}>
      <img className={style.logo} alt="logo" src="https://images.contentstack.io/v3/assets/blt4f29123a87c48c8b/blt6a7ecc525d5cc448/5b87c3effcd8df450b22f401/8x8-logo.svg" />
    </div>
  );
};
