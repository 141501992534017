"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _color = _interopRequireDefault(require("../choices/color.const"));

var _font = _interopRequireDefault(require("../choices/font.const"));

var _size = _interopRequireDefault(require("../choices/size.const"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var label = {
  fontColor: _color.default.pickledBluewood800,
  fontColorAction: _color.default.dodgerBlue,
  fontColorStarRequired: _color.default.coral,
  fontFamily: _font.default.family,
  fontSize: _font.default.sizeDefault,
  fontStretch: 'normal',
  fontWeight: _font.default.weightDefault,
  height: _size.default.medium,
  letterSpacing: 'normal',
  lineHeight: _font.default.lineHeightMedium,
  iconColor: _color.default.steel700,
  iconMarginLeft: _size.default.default,
  iconSize: 18
};
var _default = label;
exports.default = _default;