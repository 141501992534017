"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.userStatus = void 0;

var _color = _interopRequireDefault(require("../choices/color.const"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var userStatus = {
  sizeSmall: '12px',
  sizeLarge: '20px',
  borderRadius: '100%',
  borderSizeSmall: '1px',
  borderSizeLarge: '2px',
  borderColorAvailable: _color.default.white,
  borderColorAway: _color.default.white,
  borderColorBusy: _color.default.white,
  borderColorDoNotDisturb: _color.default.white,
  borderColorOffline: _color.default.white,
  borderColorOnBreak: _color.default.white,
  borderColorOnCall: _color.default.white,
  borderColorWorkingOffline: _color.default.white,
  backgroundColorAvailable: _color.default.jadeGreen,
  backgroundColorAway: _color.default.goldenBell,
  backgroundColorBusy: _color.default.coral,
  backgroundColorDoNotDisturb: _color.default.coral,
  backgroundColorOffline: _color.default.cloudyBlue,
  backgroundColorOnBreak: _color.default.steel700,
  backgroundColorOnCall: _color.default.coral,
  backgroundColorWorkingOffline: _color.default.goldenBell,
  iconColorAway: _color.default.white,
  iconColorDoNotDisturb: _color.default.white,
  iconColorOnBreak: _color.default.white,
  iconColorOnCall: _color.default.white,
  iconSizeSmall: 6,
  iconSizeLarge: 10
};
exports.userStatus = userStatus;
var _default = userStatus;
exports.default = _default;