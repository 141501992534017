"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RIGHT = exports.CENTER = exports.LEFT = void 0;
var LEFT = 'left';
exports.LEFT = LEFT;
var CENTER = 'center';
exports.CENTER = CENTER;
var RIGHT = 'right';
exports.RIGHT = RIGHT;