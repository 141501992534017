"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.RadioGroup = exports.RadioGroupContext = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _oxygenConfig = require("@8x8/oxygen-config");

var _StyledRadioGroup = _interopRequireDefault(require("../styled/StyledRadioGroup"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var RadioGroupContext = (0, _react.createContext)(null);
exports.RadioGroupContext = RadioGroupContext;

var RadioGroup = function RadioGroup(props) {
  var children = props.children,
      isHorizontal = props.isHorizontal,
      name = props.name,
      onChange = props.onChange,
      theme = props.theme,
      value = props.value,
      rest = _objectWithoutProperties(props, ["children", "isHorizontal", "name", "onChange", "theme", "value"]);

  return _react.default.createElement(RadioGroupContext.Provider, {
    value: {
      selectedValue: value,
      name: name,
      onChange: onChange
    }
  }, _react.default.createElement(_StyledRadioGroup.default, _extends({
    isHorizontal: isHorizontal,
    theme: theme
  }, rest), children));
};

exports.RadioGroup = RadioGroup;
RadioGroup.propTypes = {
  /**
   * Radio inputs to be rendered
   */
  children: _propTypes.default.oneOfType([_propTypes.default.array, _propTypes.default.node]),

  /**
   * Specifies if the group should be displayed horizontally or vertically
   */
  isHorizontal: _propTypes.default.bool,

  /**
   * Name prop to be passed to the radio inputs
   */
  name: _propTypes.default.string,

  /**
   * Callback function to be passed to the radio inputs
   */
  onChange: _propTypes.default.func,

  /**
   * Radio group theme
   */
  theme: _propTypes.default.object,

  /**
   * Specifies the selected value from the radio group
   */
  value: _propTypes.default.oneOfType([_propTypes.default.number, _propTypes.default.string, _propTypes.default.bool, _propTypes.default.shape({
    id: _propTypes.default.string.isRequired
  })])
};
RadioGroup.defaultProps = {
  children: null,
  isHorizontal: false,
  name: '',
  onChange: function onChange() {
    return false;
  },
  theme: {},
  value: ''
};

var _default = (0, _oxygenConfig.withTheme)(RadioGroup, 'radioGroup');

exports.default = _default;