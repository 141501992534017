"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var color = {
  dodgerBlue: '#0376DA',
  dodgerBlue100: '#DAEBFA',
  dodgerBlue300: '#6FB1EA',
  dodgerBlue400: '#278ADF',
  dodgerBlue700: '#1A6CB4',
  pickledBluewood: '#2A3A4B',
  pickledBluewood800: '#1C2025',
  steel700: '#5E6D7A',
  cloudyBlue: '#A4B8D1',
  cloudyBlue100: '#F0F3F7',
  cloudyBlue300: '#D1DBE8',
  cloudyBlue900: '#424A54',
  coral: '#E12D2D',
  coral400: '#E54B4B',
  coral800: '#BB2538',
  jadeGreen: '#31B76A',
  jadeGreen400: '#76CF9C',
  jadeGreen700: '#279255',
  goldenBell: '#F1AD33',
  white: '#FFF',
  candyPink: '#E45068',
  carrotOrange: '#FA6E59',
  durianYellow: '#EEB556',
  caribbeanGreen: '#51D6AA',
  ballBlue: '#48C7D3',
  bondiBlue: '#3D96BB',
  celadonBlue: '#235C6E'
};
var _default = color;
exports.default = _default;