"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _isFinite = _interopRequireDefault(require("lodash/isFinite"));

var _oxygenConstants = require("@8x8/oxygen-constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  max-width: ", ";\n  min-width: ", ";\n  width: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var getContainerWidth = function getContainerWidth(_ref) {
  var theme = _ref.theme,
      size = _ref.size,
      fullWidth = _ref.fullWidth;
  var floatSize = parseFloat(size, 10);

  if (fullWidth) {
    return '100%';
  }

  if ((0, _isFinite.default)(floatSize)) {
    if (!(0, _isFinite.default)(parseInt("".concat(size).slice(-1), 10))) {
      return size;
    }

    return "".concat(floatSize, "px");
  }

  if (size === _oxygenConstants.progressBarSize.small) {
    return theme.widthProgressBarSmall;
  }

  if (size === _oxygenConstants.progressBarSize.large) {
    return theme.widthProgressBarLarge;
  }

  return theme.widthProgressBarDefault;
};

var ProgressBarContainer = _styledComponents.default.div(_templateObject(), getContainerWidth, function (_ref2) {
  var theme = _ref2.theme;
  return theme.widthProgressBarSmall;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.widthProgressBarLarge;
});

var _default = ProgressBarContainer;
exports.default = _default;