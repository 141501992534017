"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  width: ", "px;\n  align-items: center;\n  box-sizing: border-box;\n  justify-content: center;\n  margin-left: ", ";\n  margin-right: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var InputIcon = _styledComponents.default.div(_templateObject(), function (_ref) {
  var theme = _ref.theme;
  return 24 + parseInt(theme.horizontalSpacing, 10);
}, function (_ref2) {
  var theme = _ref2.theme,
      marginLeft = _ref2.marginLeft;
  return marginLeft ? theme.horizontalSpacing : 0;
}, function (_ref3) {
  var theme = _ref3.theme,
      marginLeft = _ref3.marginLeft;
  return marginLeft ? 0 : theme.horizontalSpacing;
});

var _default = InputIcon;
exports.default = _default;