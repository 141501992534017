import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import HtmlParser from 'react-html-parser';
import Radio, { RadioGroup } from '@8x8/oxygen-radio';
import style from './payment.module.scss';
import { MainFrame } from '../../components/mainFrame';
import { Loading } from '../loading';
import { Store } from '../../store';
import { Header } from '../../components/header';

export const Payment = ({ history }) => {
  const iframeRef = useRef();
  const {
    state: {
      paymentPage, payGatewayUrl,
    }, dispatch,
  } = useContext(Store);

  const [loading, setLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [defaultRadioValue, setDefaultRadioValue] = useState('Yes');
  const [showRadioButtons, setShowRadioButton] = useState();
  const Queryvalues = queryString.parse(window.location.search);

  useEffect(() => {
    let showPrimaryFlagSelector;
    (async function fetchPaymentPage() {
      try {
        setLoading(true);
        const response = await axios.get('/api/payment');
        dispatch({
          type: 'PAYMENT_PAGE',
          payload: response.data,
        });

        try {
          const paymentDetail = await axios.get(`/api/paymentInfoById/${Queryvalues.transactionId}` );
          showPrimaryFlagSelector = paymentDetail.data.showPrimaryFlagSelector;
          setShowRadioButton(paymentDetail.data.showPrimaryFlagSelector);
          dispatch({
            type: 'PAYMENT_GATEWAY_URL',
            payload: paymentDetail.data.vendorUrl,
          });
        } catch (err) {
          history.push('/problem');
        }
        const res = await axios.get('https://api.ipify.org');
        const data = {
          ipAddress: res.data,
        };
        if (showPrimaryFlagSelector) {
          data.isPrimary = true;
        }
        try {
          await axios.post(`/api/patchPaymentMethod/${Queryvalues.transactionId}`, data);
        } catch (err) {
          history.push('/problem');
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }());
  }, [dispatch, Queryvalues.transactionId, history]);

  const updatePreference = async (value) => {
    setPaymentLoading(true);
    const data = {
      isPrimary: value === 'Yes',
    };
    try {
      await axios.post(`/api/patchPaymentMethod/${Queryvalues.transactionId}`, data );
      setDefaultRadioValue(value);
    } catch (err) {
      history.push('/problem');
    }
    setPaymentLoading(false);
  };

  return (
    loading ? <Loading /> : (
      <>
        <Header />
        <div className={style.paymentWraper}>
          <MainFrame paymentLoading={paymentLoading}>
            {
              paymentLoading && (
                <Loading className={style.overlayLoading} header={false} />
              )
            }
            {
              showRadioButtons && (
                <>
                  <p>
                    {
                      paymentPage
                      && paymentPage.entries[0].label_references[0].label_desktop_version
                    }
                  </p>
                  <RadioGroup onChange={updatePreference} value={defaultRadioValue}>
                    <Radio
                      label={paymentPage && paymentPage.entries[0].radio_group.value_1}
                      value={paymentPage && paymentPage.entries[0].radio_group.value_1}
                    />
                    <Radio
                      label={paymentPage && paymentPage.entries[0].radio_group.value_2}
                      value={paymentPage && paymentPage.entries[0].radio_group.value_2}
                    />
                  </RadioGroup>
                  {
                      HtmlParser(paymentPage && paymentPage.entries[0].information)
                  }
                </>
              )
            }
            <div className={style.iframe}>
              <iframe
                src={payGatewayUrl}
                title="iframe"
                id="frameSize"
                ref={iframeRef}
                width="100%"
                height="630px"
                frameBorder="0"
                sandbox="allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-top-navigation"
              />
            </div>
          </MainFrame>
        </div>
      </>
    )
  );
};

Payment.propTypes = {
  history: PropTypes.object,
};
