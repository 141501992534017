"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Bar = exports.FallbackBarProgress = exports.FallbackBarContainer = exports.FallbackBar = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  ", "\n\n  &::-webkit-progress-bar,\n  &[value]::-webkit-progress-bar {\n    ", "\n  }\n\n  &[value]::-webkit-progress-value {\n    ", "\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var borderError = 1;

var getBarStyles = function getBarStyles(_ref) {
  var theme = _ref.theme;
  return "\n  appearance: none;\n  border-radius: ".concat(theme.borderRadius, ";\n  height: ").concat(theme.height, ";\n  margin-bottom: ").concat(parseInt(theme.height, 10) - borderError, "px;\n  overflow: hidden;\n  width: 100%;\n");
};

var getBarContainerStyles = function getBarContainerStyles(_ref2) {
  var theme = _ref2.theme;
  return "\n  background-color: ".concat(theme.backgroundContainer, ";\n  border-radius: ").concat(theme.borderRadius, ";\n  border: ", "".concat(theme.borderSize, " ").concat(theme.borderStyle, " ").concat(theme.borderColor), ";\n");
};

var getBarProgressStyles = function getBarProgressStyles(_ref3) {
  var theme = _ref3.theme;
  return "\n  background-color: ".concat(theme.backgroundFiller, ";\n  border-radius: ").concat(theme.borderRadius, ";\n  box-shadow: 2px 0 0 ").concat(theme.backgroundFiller, ";\n  height: ").concat(theme.height, ";\n  margin-left: -1px;\n  margin-top: -1px;\n  transition: ").concat(theme.transitionSpeedProgressBar, " width linear;\n");
};

var FallbackBar = _styledComponents.default.div(_templateObject(), getBarStyles);

exports.FallbackBar = FallbackBar;

var FallbackBarContainer = _styledComponents.default.div(_templateObject2(), getBarContainerStyles);

exports.FallbackBarContainer = FallbackBarContainer;

var FallbackBarProgress = _styledComponents.default.span(_templateObject3(), getBarProgressStyles);

exports.FallbackBarProgress = FallbackBarProgress;

var Bar = _styledComponents.default.progress(_templateObject4(), getBarStyles, getBarContainerStyles, getBarProgressStyles);

exports.Bar = Bar;