"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var size = {
  smallest: '2px',
  small: '4px',
  default: '8px',
  default2x: '16px',
  medium: '24px',
  large: '40px',
  large2x: '72px',
  tableHeaderSize: '48px',
  tableRowSize: '48px',
  avatarSmallest: '16px',
  avatarSmall: '24px',
  avatarDefault: '32px',
  avatarMedium: '40px',
  avatarMediumPlus: '48px',
  avatarLarge: '64px',
  avatarLargePlus: '72px',
  avatarAttention: '80px',
  avatarMax: '160px',
  borderRadiusSmall: '2px',
  borderRadiusDefault: '3px',
  borderRadiusMedium: '5px',
  borderSizeDefault: '1px',
  borderSizeLarge: '4px',
  shadowSmall: '0 1px 0 0',
  shadowDefault: '0 2px 2px 0',
  shadowMedium: '0 4px 8px 0',
  formItemSizeLarge: '40px',
  formItemSizeSmall: '32px',
  viewBox: '0 0 24 24',
  windowWidthDesktop: 992,
  windowWidthTablet: 768,
  windowWidthPhone: 576
};
var _default = size;
exports.default = _default;