"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.dropdownButton = void 0;

var _polished = require("polished");

var _color = _interopRequireDefault(require("../choices/color.const"));

var _size = _interopRequireDefault(require("../choices/size.const"));

var _button = require("../decisions/button.const");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var dropdownButton = {
  button: _objectSpread({}, _button.button),
  indicatorLeftMargin: _size.default.default,
  indicatorScale: 1,
  indicatorColor: _color.default.steel700,
  indicatorColorDisabled: _color.default.cloudyBlue,
  dropdownOffset: '0',
  dropdownMaxHeight: '200px',
  dropdownMaxWidth: '200px',
  dropdownHorizontalPadding: '5px',
  dropdownBackgroundColor: _color.default.white,
  dropdownBorderColor: _color.default.cloudyBlue300,
  dropdownShadowSize: '0 1px 5px 0',
  dropdownShadowColor: (0, _polished.rgba)(_color.default.pickledBluewood800, 0.1)
};
exports.dropdownButton = dropdownButton;
var _default = dropdownButton;
exports.default = _default;