"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var getBorderColor = function getBorderColor(_ref) {
  var theme = _ref.theme,
      hasError = _ref.hasError,
      focused = _ref.focused,
      fixed = _ref.fixed,
      defaultColor = _ref.defaultColor;

  if (hasError) {
    return theme.borderColorError;
  }

  if (fixed) {
    return theme.borderColorFixed;
  }

  if (focused) {
    return theme.borderColorFocused;
  }

  return defaultColor || theme.borderColor;
};

var _default = getBorderColor;
exports.default = _default;