"use strict";
var global = require("global")
var document = require("global/document")
var window = require("global/window")

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "directions", {
  enumerable: true,
  get: function get() {
    return _constants.directions;
  }
});
Object.defineProperty(exports, "directionsType", {
  enumerable: true,
  get: function get() {
    return _constants.directionsType;
  }
});
Object.defineProperty(exports, "spacesIndexMap", {
  enumerable: true,
  get: function get() {
    return _constants.spacesIndexMap;
  }
});
Object.defineProperty(exports, "directionsIndexMap", {
  enumerable: true,
  get: function get() {
    return _constants.directionsIndexMap;
  }
});
Object.defineProperty(exports, "marginsIndexMap", {
  enumerable: true,
  get: function get() {
    return _constants.marginsIndexMap;
  }
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactDom = require("react-dom");

var _propTypes = _interopRequireDefault(require("prop-types"));

var _noop = _interopRequireDefault(require("lodash/noop"));

var _controller = _interopRequireDefault(require("./components/controller"));

var _constants = require("./constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Flyout = function Flyout(_ref) {
  var anchor = _ref.anchor,
      idealDirection = _ref.idealDirection,
      testId = _ref.testId,
      isShown = _ref.isShown,
      margin = _ref.margin,
      shouldFocus = _ref.shouldFocus,
      onBlur = _ref.onBlur,
      children = _ref.children,
      targetNode = _ref.targetNode;
  if (!anchor || !isShown) return null;
  return (0, _reactDom.createPortal)(_react.default.createElement(_controller.default, {
    anchor: anchor,
    idealDirection: idealDirection,
    testId: testId,
    margin: margin,
    shouldFocus: shouldFocus,
    onBlur: onBlur
  }, children), targetNode);
};

Flyout.propTypes = {
  anchor: _propTypes.default.oneOfType([_propTypes.default.object, _propTypes.default.func]),
  idealDirection: _propTypes.default.oneOf(_constants.directionsType),
  testId: _propTypes.default.string,
  isShown: _propTypes.default.bool,
  margin: _propTypes.default.number,
  shouldFocus: _propTypes.default.bool,
  onBlur: _propTypes.default.func,
  children: _propTypes.default.node.isRequired,
  targetNode: _propTypes.default.oneOfType([_propTypes.default.instanceOf(window.Element), _propTypes.default.element])
};
Flyout.defaultProps = {
  anchor: null,
  idealDirection: _constants.directions.bottom,
  testId: 'FLYOUT',
  isShown: false,
  margin: undefined,
  shouldFocus: true,
  onBlur: _noop.default,
  targetNode: document.body
};
var _default = Flyout;
exports.default = _default;