"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _color = _interopRequireDefault(require("../choices/color.const"));

var _size = _interopRequireDefault(require("../choices/size.const"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var icon = {
  color: _color.default.pickledBluewood800,
  linkColor: _color.default.steel700,
  hoverColor: _color.default.dodgerBlue,
  selectedColor: _color.default.dodgerBlue,
  disabledColor: _color.default.dodgerBlue300,
  noLinkInvertColor: _color.default.white,
  linkInvertColor: _color.default.cloudyBlue,
  hoverInvertColor: _color.default.white,
  selectedInvertColor: _color.default.white,
  disabledInvertColor: _color.default.steel700,
  size: _size.default.medium,
  viewBox: _size.default.viewBox
};
var _default = icon;
exports.default = _default;